// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "../../../environments/environment";
import { LocalStorage } from '../../shared/services/storage.service';

export interface Locale {
  lang: string;
  data: any;
}

const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {

  /**
   * Event that fires when the app is translated
   */
  static TRANSLATED = new EventEmitter<string>();
  private selectedLanguage: string;

  private langIds: any = [];

  constructor(
    private translate: TranslateService,
    private storage: LocalStorage,
  ) {
    // add new langIds to the list
    this.translate.addLangs(['en']);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(environment.appLanguage);
  }

  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data.default, true);
      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  setLanguage(lang) {
    if (lang) {
      this.translateTo(lang);
      this.setLanguageToStorage(lang);
      if (lang !== this.selectedLanguage) {
        TranslationService.TRANSLATED.emit(lang);
        this.selectedLanguage = lang;
      }
    }
  }

  translateTo(lang) {
    this.translate.use(this.translate.getDefaultLang()); // fallback if language translation does not exists
    this.translate.use(lang);
  }

  /**
   * Returns selected language from local storage
   */
   getLanguageFromStorage(): any {
    return (
      this.storage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY) ||
      this.translate.getDefaultLang()
    );
  }

  setLanguageToStorage(lang) {
    this.storage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, lang);
  }
}
