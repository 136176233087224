import { BaseModel } from './base.model';
import { COUPON_TYPES, PAYMENT_METHOD } from '../globals';
import { NumberHelper } from '../helpers/number.helper';

export class CouponModel extends BaseModel {
  id: number;
  name: string;
  code: string;
  value: number;
  type: string;
  paymentTypes: string;
  isActive: boolean;

  getPaymentTypes(): string[] {
    return this.paymentTypes.split(',');
  }

  getDiscountValue(total: number) {
    switch (this.type) {
      case COUPON_TYPES.amount:
        return Math.min(this.value, total);
      case COUPON_TYPES.percentage:
        return NumberHelper.roundToTwoDecimals(NumberHelper.percentage(total, this.value));
      default: return null;
    }
  }

  get hasCashPayment(): boolean {
    return !!this.getPaymentTypes().filter(type => type === PAYMENT_METHOD.cash);
  }
}
