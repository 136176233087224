import { Component, OnDestroy } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UserModel } from "../../models/user.model";
import { UserService } from "../../services/user.service";
import { ModalService } from "../../services/modal.service";
import { AuthService } from "src/app/modules/auth";
import { RedirectHelper } from "../../helpers/redirect.helper";
import { CartService } from "../../services/cart.service";
import { RESTAURANT_TABLE_ROLES } from "../../globals";
import { LocalStorage } from "../../services/storage.service";

@Component({
  selector: "app-user-nav-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  user: UserModel = new UserModel();
  private modalRef: NgbModalRef;
  register = false;
  login = true;
  forgotPassword = false;
  isCollapsed = true;
  modalVisible = false;
  restaurantSlug: string;
  tableCode: string;
  TYPE_SIGNIN = "signin";
  TYPE_LOGIN = "login";
  hasUserRole = false;
  rolesToCheck = RESTAURANT_TABLE_ROLES;

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private router: Router,
    private cartService: CartService,
    private storage: LocalStorage
  ) {
    this.subscriptions.push(
      this.userService.user.subscribe((user) => {
        this.user = user;
        if (user && user.role && this.rolesToCheck.includes(user.role)) {
          this.hasUserRole = true;
        }
      })
    );
    this.subscriptions.push(
      AuthService.LOGIN.subscribe((e) => {
        if (this.modalRef) {
          this.modalRef.close();
        }
      })
    );
    this.subscriptions.push(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          if (this.modalRef) {
            this.modalRef.close();
          }
        }
      })
    );
  }

  ngOnInit(): void {
    this.restaurantSlug = this.getLastVisitedRestaurant();
    this.tableCode = this.getLastVisitedTableCode();
  }

  showUserCanvas(): void {
    this.modalVisible = true;
  }

  hideUserCanvas(): void {
    this.modalVisible = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  logout(): void {
    this.userService.logout();
    this.cartService.removeOrderAddress();
    this.router.navigateByUrl(RedirectHelper.logoutRedirectUrl);
  }

  openModal(modal, type) {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }

    if (type === this.TYPE_SIGNIN) {
      this.openRegistrationForm();
    } else if (type === this.TYPE_LOGIN) {
      this.openLoginForm();
    }

    this.modalRef = this.modalService.open(modal, {
      centered: true,
      size: "md",
    });
  }

  closeModal(): void {
    this.modalRef.close(false);
  }

  openLoginForm(): void {
    this.login = true;
    this.register = false;
    this.forgotPassword = false;
  }

  openRegistrationForm(): void {
    this.login = false;
    this.register = true;
    this.forgotPassword = false;
  }

  openForgotPasswordForm(): void {
    this.login = false;
    this.register = false;
    this.forgotPassword = true;
  }

  goToHomePage() {
    if (this.tableCode) {
      this.router.navigate([
        "restaurants",
        this.getLastVisitedRestaurant(),
        "welcome",
        this.tableCode,
      ]);
    } else {
      this.router.navigate([
        "restaurants",
        this.getLastVisitedRestaurant(),
        "welcome",
      ]);
    }
  }

  goToRestaurantTables() {
    if (this.hasUserRole) {
      this.router.navigate([
        "restaurants",
        this.getLastVisitedRestaurant(),
        "tables",
      ]);
    }
  }

  private getLastVisitedRestaurant(): string {
    return this.storage.getItem("last-visited-restaurant-slug");
  }

  private getLastVisitedTableCode(): string {
    return this.storage.getItem("last-visited-restaurant-table-code");
  }
}
