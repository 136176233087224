import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../../../shared/models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import { map } from 'rxjs/operators';
import { QueryParamsInterface } from '../../../../shared/interfaces/query-params.interface';

const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(
    private http: HttpClient,
  ) {
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${environment.apiUrl}/oauth/token`, {
      username: email,
      password,
      grant_type: 'password',
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    }).pipe(
      map(response => {
        return new AuthModel().setAuth(response);
      })
    );
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_USERS_URL}/customer`, user.toApi());
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  resetPassword(password: string, passwordRepeat: string, token:string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/reset-password`, {
      new_password: password,
      password_repeat: passwordRepeat,
      reset_token: token
    });
  }

  getUserByToken(token, params = {}): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/me`, {
      headers: httpHeaders,
      params
    });
  }
}
