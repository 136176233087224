import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const API = {
  USERS_URL: `/users`
};

export const RESTAURANTS_URL = 'restaurants';
export const USER_PROFILE_URL = 'user/profile';

export const EMAIL_REGEX = '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const MOBILE_BREAKPOINT = 768;

export const DEFAULT_PREPARATION_TIME = 15;

export const LAST_VISITED_RESTAURANT_SLUG = 'last-visited-restaurant-slug';
export const LAST_VISITED_RESTAURANT_TABLE_CODE = 'last-visited-restaurant-table-code';
export const DELIVERY_TYPE = 'delivery-type';

export const COUNTRIES = [
  {name: 'Afghanistan', code: 'AF'},
  {name: 'Åland Islands', code: 'AX'},
  {name: 'Albania', code: 'AL'},
  {name: 'Algeria', code: 'DZ'},
  {name: 'American Samoa', code: 'AS'},
  {name: 'AndorrA', code: 'AD'},
  {name: 'Angola', code: 'AO'},
  {name: 'Anguilla', code: 'AI'},
  {name: 'Antarctica', code: 'AQ'},
  {name: 'Antigua and Barbuda', code: 'AG'},
  {name: 'Argentina', code: 'AR'},
  {name: 'Armenia', code: 'AM'},
  {name: 'Aruba', code: 'AW'},
  {name: 'Australia', code: 'AU'},
  {name: 'Austria', code: 'AT'},
  {name: 'Azerbaijan', code: 'AZ'},
  {name: 'Bahamas', code: 'BS'},
  {name: 'Bahrain', code: 'BH'},
  {name: 'Bangladesh', code: 'BD'},
  {name: 'Barbados', code: 'BB'},
  {name: 'Belarus', code: 'BY'},
  {name: 'Belgium', code: 'BE'},
  {name: 'Belize', code: 'BZ'},
  {name: 'Benin', code: 'BJ'},
  {name: 'Bermuda', code: 'BM'},
  {name: 'Bhutan', code: 'BT'},
  {name: 'Bolivia', code: 'BO'},
  {name: 'Bosnia and Herzegovina', code: 'BA'},
  {name: 'Botswana', code: 'BW'},
  {name: 'Bouvet Island', code: 'BV'},
  {name: 'Brazil', code: 'BR'},
  {name: 'British Indian Ocean Territory', code: 'IO'},
  {name: 'Brunei Darussalam', code: 'BN'},
  {name: 'Bulgaria', code: 'BG'},
  {name: 'Burkina Faso', code: 'BF'},
  {name: 'Burundi', code: 'BI'},
  {name: 'Cambodia', code: 'KH'},
  {name: 'Cameroon', code: 'CM'},
  {name: 'Canada', code: 'CA'},
  {name: 'Cape Verde', code: 'CV'},
  {name: 'Cayman Islands', code: 'KY'},
  {name: 'Central African Republic', code: 'CF'},
  {name: 'Chad', code: 'TD'},
  {name: 'Chile', code: 'CL'},
  {name: 'China', code: 'CN'},
  {name: 'Christmas Island', code: 'CX'},
  {name: 'Cocos (Keeling) Islands', code: 'CC'},
  {name: 'Colombia', code: 'CO'},
  {name: 'Comoros', code: 'KM'},
  {name: 'Congo', code: 'CG'},
  {name: 'Congo, The Democratic Republic of the', code: 'CD'},
  {name: 'Cook Islands', code: 'CK'},
  {name: 'Costa Rica', code: 'CR'},
  {name: 'Cote D\'Ivoire', code: 'CI'},
  {name: 'Croatia', code: 'HR'},
  {name: 'Cuba', code: 'CU'},
  {name: 'Cyprus', code: 'CY'},
  {name: 'Czech Republic', code: 'CZ'},
  {name: 'Denmark', code: 'DK'},
  {name: 'Djibouti', code: 'DJ'},
  {name: 'Dominica', code: 'DM'},
  {name: 'Dominican Republic', code: 'DO'},
  {name: 'Ecuador', code: 'EC'},
  {name: 'Egypt', code: 'EG'},
  {name: 'El Salvador', code: 'SV'},
  {name: 'Equatorial Guinea', code: 'GQ'},
  {name: 'Eritrea', code: 'ER'},
  {name: 'Estonia', code: 'EE'},
  {name: 'Ethiopia', code: 'ET'},
  {name: 'Falkland Islands (Malvinas)', code: 'FK'},
  {name: 'Faroe Islands', code: 'FO'},
  {name: 'Fiji', code: 'FJ'},
  {name: 'Finland', code: 'FI'},
  {name: 'France', code: 'FR'},
  {name: 'French Guiana', code: 'GF'},
  {name: 'French Polynesia', code: 'PF'},
  {name: 'French Southern Territories', code: 'TF'},
  {name: 'Gabon', code: 'GA'},
  {name: 'Gambia', code: 'GM'},
  {name: 'Georgia', code: 'GE'},
  {name: 'Germany', code: 'DE'},
  {name: 'Ghana', code: 'GH'},
  {name: 'Gibraltar', code: 'GI'},
  {name: 'Greece', code: 'GR'},
  {name: 'Greenland', code: 'GL'},
  {name: 'Grenada', code: 'GD'},
  {name: 'Guadeloupe', code: 'GP'},
  {name: 'Guam', code: 'GU'},
  {name: 'Guatemala', code: 'GT'},
  {name: 'Guernsey', code: 'GG'},
  {name: 'Guinea', code: 'GN'},
  {name: 'Guinea-Bissau', code: 'GW'},
  {name: 'Guyana', code: 'GY'},
  {name: 'Haiti', code: 'HT'},
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
  {name: 'Holy See (Vatican City State)', code: 'VA'},
  {name: 'Honduras', code: 'HN'},
  {name: 'Hong Kong', code: 'HK'},
  {name: 'Hungary', code: 'HU'},
  {name: 'Iceland', code: 'IS'},
  {name: 'India', code: 'IN'},
  {name: 'Indonesia', code: 'ID'},
  {name: 'Iran, Islamic Republic Of', code: 'IR'},
  {name: 'Iraq', code: 'IQ'},
  {name: 'Ireland', code: 'IE'},
  {name: 'Isle of Man', code: 'IM'},
  {name: 'Israel', code: 'IL'},
  {name: 'Italy', code: 'IT'},
  {name: 'Jamaica', code: 'JM'},
  {name: 'Japan', code: 'JP'},
  {name: 'Jersey', code: 'JE'},
  {name: 'Jordan', code: 'JO'},
  {name: 'Kazakhstan', code: 'KZ'},
  {name: 'Kenya', code: 'KE'},
  {name: 'Kiribati', code: 'KI'},
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
  {name: 'Korea, Republic of', code: 'KR'},
  {name: 'Kuwait', code: 'KW'},
  {name: 'Kyrgyzstan', code: 'KG'},
  {name: 'Lao People\'S Democratic Republic', code: 'LA'},
  {name: 'Latvia', code: 'LV'},
  {name: 'Lebanon', code: 'LB'},
  {name: 'Lesotho', code: 'LS'},
  {name: 'Liberia', code: 'LR'},
  {name: 'Libyan Arab Jamahiriya', code: 'LY'},
  {name: 'Liechtenstein', code: 'LI'},
  {name: 'Lithuania', code: 'LT'},
  {name: 'Luxembourg', code: 'LU'},
  {name: 'Macao', code: 'MO'},
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
  {name: 'Madagascar', code: 'MG'},
  {name: 'Malawi', code: 'MW'},
  {name: 'Malaysia', code: 'MY'},
  {name: 'Maldives', code: 'MV'},
  {name: 'Mali', code: 'ML'},
  {name: 'Malta', code: 'MT'},
  {name: 'Marshall Islands', code: 'MH'},
  {name: 'Martinique', code: 'MQ'},
  {name: 'Mauritania', code: 'MR'},
  {name: 'Mauritius', code: 'MU'},
  {name: 'Mayotte', code: 'YT'},
  {name: 'Mexico', code: 'MX'},
  {name: 'Micronesia, Federated States of', code: 'FM'},
  {name: 'Moldova, Republic of', code: 'MD'},
  {name: 'Monaco', code: 'MC'},
  {name: 'Mongolia', code: 'MN'},
  {name: 'Montenegro', code: 'ME'},
  {name: 'Montserrat', code: 'MS'},
  {name: 'Morocco', code: 'MA'},
  {name: 'Mozambique', code: 'MZ'},
  {name: 'Myanmar', code: 'MM'},
  {name: 'Namibia', code: 'NA'},
  {name: 'Nauru', code: 'NR'},
  {name: 'Nepal', code: 'NP'},
  {name: 'Netherlands', code: 'NL'},
  {name: 'Netherlands Antilles', code: 'AN'},
  {name: 'New Caledonia', code: 'NC'},
  {name: 'New Zealand', code: 'NZ'},
  {name: 'Nicaragua', code: 'NI'},
  {name: 'Niger', code: 'NE'},
  {name: 'Nigeria', code: 'NG'},
  {name: 'Niue', code: 'NU'},
  {name: 'Norfolk Island', code: 'NF'},
  {name: 'Northern Mariana Islands', code: 'MP'},
  {name: 'Norway', code: 'NO'},
  {name: 'Oman', code: 'OM'},
  {name: 'Pakistan', code: 'PK'},
  {name: 'Palau', code: 'PW'},
  {name: 'Palestinian Territory, Occupied', code: 'PS'},
  {name: 'Panama', code: 'PA'},
  {name: 'Papua New Guinea', code: 'PG'},
  {name: 'Paraguay', code: 'PY'},
  {name: 'Peru', code: 'PE'},
  {name: 'Philippines', code: 'PH'},
  {name: 'Pitcairn', code: 'PN'},
  {name: 'Poland', code: 'PL'},
  {name: 'Portugal', code: 'PT'},
  {name: 'Puerto Rico', code: 'PR'},
  {name: 'Qatar', code: 'QA'},
  {name: 'Reunion', code: 'RE'},
  {name: 'Romania', code: 'RO'},
  {name: 'Russian Federation', code: 'RU'},
  {name: 'RWANDA', code: 'RW'},
  {name: 'Saint Helena', code: 'SH'},
  {name: 'Saint Kitts and Nevis', code: 'KN'},
  {name: 'Saint Lucia', code: 'LC'},
  {name: 'Saint Pierre and Miquelon', code: 'PM'},
  {name: 'Saint Vincent and the Grenadines', code: 'VC'},
  {name: 'Samoa', code: 'WS'},
  {name: 'San Marino', code: 'SM'},
  {name: 'Sao Tome and Principe', code: 'ST'},
  {name: 'Saudi Arabia', code: 'SA'},
  {name: 'Senegal', code: 'SN'},
  {name: 'Serbia and Montenegro', code: 'CS'},
  {name: 'Seychelles', code: 'SC'},
  {name: 'Sierra Leone', code: 'SL'},
  {name: 'Singapore', code: 'SG'},
  {name: 'Slovakia', code: 'SK'},
  {name: 'Slovenia', code: 'SI'},
  {name: 'Solomon Islands', code: 'SB'},
  {name: 'Somalia', code: 'SO'},
  {name: 'South Africa', code: 'ZA'},
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
  {name: 'Spain', code: 'ES'},
  {name: 'Sri Lanka', code: 'LK'},
  {name: 'Sudan', code: 'SD'},
  {name: 'Suriname', code: 'SR'},
  {name: 'Svalbard and Jan Mayen', code: 'SJ'},
  {name: 'Swaziland', code: 'SZ'},
  {name: 'Sweden', code: 'SE'},
  {name: 'Switzerland', code: 'CH'},
  {name: 'Syrian Arab Republic', code: 'SY'},
  {name: 'Taiwan, Province of China', code: 'TW'},
  {name: 'Tajikistan', code: 'TJ'},
  {name: 'Tanzania, United Republic of', code: 'TZ'},
  {name: 'Thailand', code: 'TH'},
  {name: 'Timor-Leste', code: 'TL'},
  {name: 'Togo', code: 'TG'},
  {name: 'Tokelau', code: 'TK'},
  {name: 'Tonga', code: 'TO'},
  {name: 'Trinidad and Tobago', code: 'TT'},
  {name: 'Tunisia', code: 'TN'},
  {name: 'Turkey', code: 'TR'},
  {name: 'Turkmenistan', code: 'TM'},
  {name: 'Turks and Caicos Islands', code: 'TC'},
  {name: 'Tuvalu', code: 'TV'},
  {name: 'Uganda', code: 'UG'},
  {name: 'Ukraine', code: 'UA'},
  {name: 'United Arab Emirates', code: 'AE'},
  {name: 'United Kingdom', code: 'GB'},
  {name: 'United States', code: 'US'},
  {name: 'United States Minor Outlying Islands', code: 'UM'},
  {name: 'Uruguay', code: 'UY'},
  {name: 'Uzbekistan', code: 'UZ'},
  {name: 'Vanuatu', code: 'VU'},
  {name: 'Venezuela', code: 'VE'},
  {name: 'Viet Nam', code: 'VN'},
  {name: 'Virgin Islands, British', code: 'VG'},
  {name: 'Virgin Islands, U.S.', code: 'VI'},
  {name: 'Wallis and Futuna', code: 'WF'},
  {name: 'Western Sahara', code: 'EH'},
  {name: 'Yemen', code: 'YE'},
  {name: 'Zambia', code: 'ZM'},
  {name: 'Zimbabwe', code: 'ZW'}
];

export const COMMISSION = {
  TYPES: [
    {
      key: _('Percentage'),
      value: 'percentage'
    },
    {
      key: _('Per order'),
      value: 'per-order'
    },
    {
      key: _('Fixed'),
      value: 'fixed'
    },
    {
      key: _('Monthly'),
      value: 'monthly'
    },
    {
      key: _('Yearly'),
      value: 'yearly'
    },
  ],
  DEFAULT_TYPE: 'percentage',
  DEFAULT_AMOUNT: 2.00
};

export const COUPON_TYPES = {
  percentage: 'percentage',
  amount: 'amount'
};

export const COUPON = {
  TYPES: [
    {
      key: COUPON_TYPES.percentage,
      value: _('Percentage'),
      color: 'primary'
    },
    {
      key: COUPON_TYPES.amount,
      value: _('Amount'),
      color: 'success'
    }
  ]
};

export const PAYMENT_METHOD = {
  cash: 'cash',
  creditCard: 'credit-card',
  applePay: 'apple-pay',
  googlePay: 'google-pay',
  twint: 'twint',
};

export const PAYMENT_METHODS = [
  {
    key: _('Cash'),
    value: PAYMENT_METHOD.cash
  },
  {
    key: _('Credit Card'),
    value: PAYMENT_METHOD.creditCard
  },
  {
    key: _('Apple pay'),
    value: PAYMENT_METHOD.applePay
  },
  {
    key: _('Google pay'),
    value: PAYMENT_METHOD.googlePay
  },
  {
    key: _('Twint'),
    value: PAYMENT_METHOD.twint
  }
];

export const ORDER_TYPE = {
  delivery: 'delivery',
  takeaway: 'takeaway',
  inRestaurant: 'in-restaurant'
};

export const ORDER_TYPES = [
  {
    key: _('Delivery'),
    value: ORDER_TYPE.delivery
  },
  {
    key: _('Takeaway'),
    value: ORDER_TYPE.takeaway
  },
  {
    key: _('In Restaurant'),
    value: ORDER_TYPE.inRestaurant
  }
];

export const ROLE = {
  admin: 'admin',
  manager: 'manager',
  customer: 'customer',
  staff: 'staff'
};

export const ROLES = [
  {
    key: _('Admin'),
    value: ROLE.admin
  },
  {
    key: _('Manager'),
    value: ROLE.manager
  },
  {
    key: _('Staff'),
    value: ROLE.staff
  },
  {
    key: _('Customer'),
    value: ROLE.customer
  },
];

export const WEEK_DAYS = [
  {
    value: _('Monday'),
    key: 'monday'
  },
  {
    value: _('Tuesday'),
    key: 'tuesday'
  },
  {
    value: _('Wednesday'),
    key: 'wednesday'
  },
  {
    value: _('Thursday'),
    key: 'thursday'
  },
  {
    value: _('Friday'),
    key: 'friday'
  },
  {
    value: _('Saturday'),
    key: 'saturday'
  },
  {
    value: _('Sunday'),
    key: 'sunday'
  },
];

export const ORDER_STATUS = {
  pending: 'pending',
  accepted: 'accepted',
  preparing: 'preparing',
  ready: 'ready',
  finished: 'finished',
  canceled: 'canceled'
};

export const PAYMENT_STATUS = {
  pending: 'pending',
  captured: 'captured',
  paid: 'paid',
  refunded: 'refunded',
  created: 'created',
  canceled: 'canceled',
  failed: 'failed',
  settled: 'settled'
};

export const PAYMENT_STATUSES = [
  {
    value: _('Created'),
    key: PAYMENT_STATUS.created,
    color: 'light'
  },
  {
    value: _('Canceled'),
    key: PAYMENT_STATUS.canceled,
    color: 'warning'
  },
  {
    value: _('Failed'),
    key: PAYMENT_STATUS.failed,
    color: 'danger'
  },
  {
    value: _('Settled'),
    key: PAYMENT_STATUS.settled,
    color: 'success'
  }
]

export const ADDRESS_TYPE = {
  home: 'home',
  work: 'work',
  other: 'other'
};

export const ADDRESS_TYPES = [
  {
    value: _('Home'),
    key: ADDRESS_TYPE.home,
    icon: 'fa-home'
  },
  {
    value: _('Work'),
    key: ADDRESS_TYPE.work,
    icon: 'fa-briefcase'
  },
  {
    value: _('Other'),
    key: ADDRESS_TYPE.other,
    icon: 'fa-location-dot'
  },
];

export const ORDER_STATUSES = [
  {
    value: _('Pending'),
    key: ORDER_STATUS.pending,
    color: 'info'
  },
  {
    value: _('Accepted'),
    key: ORDER_STATUS.accepted,
    color: 'info'
  },
  {
    value: _('Preparing'),
    key: ORDER_STATUS.preparing,
    color: 'warning'
  },
  {
    value: _('Ready'),
    key: ORDER_STATUS.ready,
    color: 'dark'
  },
  {
    value: _('Finished'),
    key: ORDER_STATUS.finished,
    color: 'success'
  },
  {
    value: _('Canceled'),
    key: ORDER_STATUS.canceled,
    color: 'danger'
  }
];

export const ORDER_CACHE_KEY = 'last-order';

export const TIP_VALUE = 'tip_value';

export const ADDRESS_CACHE_KEY = 'selected-order-address';

export const GUEST_CACHE_KEY = 'guest-user';

export const RESTAURANT_TABLE_ROLES = ["admin", "manager", "staff"];

export const LANGUAGES = [
  {
    lang: 'en',
    name: 'English',
    flag: './assets/media/svg/flags/012-uk.svg',
  },
  {
    lang: 'de',
    name: 'Deutsch',
    flag: './assets/media/svg/flags/162-germany.svg',
  },
  {
    lang: 'fr',
    name: 'Français',
    flag: './assets/media/svg/flags/195-france.svg',
  },
  {
    lang: 'it',
    name: 'Italiano',
    flag: './assets/media/svg/flags/013-italy.svg',
  },
  {
    lang: 'sr',
    name: 'Srpski',
    flag: './assets/media/svg/flags/071-serbia.svg',
  },
];

export const CURRENCIES = [
  {
    code: 'CHF',
    value: 'CHF ',
    isPrefix: true,
    name: 'Switzerland Franc'
},
{
    code: 'USD',
    value: '$',
    isPrefix: true,
    name: 'United States Dollar'
},
{
    code: 'EUR',
    value: '€',
    isPrefix: false,
    name: 'Euro'
},
{
    code: 'BAM',
    value: 'KM',
    isPrefix: false,
    name: 'Bosnia & Herzegovina Convertible Mark'
}
];
