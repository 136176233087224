import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { BaseApiService } from "./base-api.service";
import { catchError, map } from "rxjs/operators";
import { MenuModel, TableModel } from "../models/menu.model";
import { RestaurantModel } from "../models/restaurant.model";
import { HttpResponseHelper } from "../helpers/http-response.helper";
import { CouponModel } from "../models/coupon.model";
import { environment } from "../../../environments/environment";
import { HttpResponse } from "@angular/common/http";
import { QueryParamsInterface } from "../interfaces/query-params.interface";
import { CURRENCIES } from "../globals";
import { ScriptService } from "./script.service";

const API_ORDER_RATINGS_URL = "/order-ratings";
const API_RESTAURANTS_URL = '/restaurants';

@Injectable({
  providedIn: "root",
})
export class RestaurantService {
  private _restaurant: RestaurantModel;
  private _restaurantSubject: Subject<RestaurantModel>;
  private _restaurantObservable: Observable<RestaurantModel>;
  restaurantMenu: MenuModel;
  private tableNameSource = new BehaviorSubject<string>("");
  tableName$ = this.tableNameSource.asObservable();

  public static currency: string;
  public static currentRestaurant: RestaurantModel;


  constructor(
    private api: BaseApiService,
    private scriptService: ScriptService
  ) {
    this._restaurantSubject = new Subject<RestaurantModel>();
    this._restaurantObservable = this._restaurantSubject.asObservable();
  }

  public static getCurrencyConfig() {
    const currencyCode = RestaurantService.currency || environment.appCurrency;
    return CURRENCIES.find((currency) => currency.code === currencyCode);
  }

  public static getRestaurantContext() {
    return this.currentRestaurant;
  }

  getRestaurantContext() {
    return this._restaurantObservable;
  }

  getMenu(restaurantSlug: string, tableCode: string = "", params: any = {}): Observable<MenuModel> {
    const menuPath = `/restaurants/${restaurantSlug}` + (tableCode ? `/${tableCode}` : "");

    return this.api.get(menuPath, params).pipe(
      map((response) => {
        if (HttpResponseHelper.requestPassed(response)) {
          const menu = new MenuModel(response.body);
          this.updateRestaurantContext(menu.restaurant || new RestaurantModel());
          this.restaurantMenu = menu;
          return menu;
        }
        this.restaurantMenu = undefined;
        return undefined;
      }),
      catchError(
        this.handleError<MenuModel>(
          `getMenu restaurant key=${restaurantSlug}, tableCode=${tableCode}`
        )
      )
    );
  }

  getCoupon(
    restaurantSlug: string,
    couponCode: string
  ): Observable<CouponModel> {
    const path = `/restaurants/${restaurantSlug}/coupons/${couponCode}`;

    return this.api.get(path, {}).pipe(
      map((response) => {
        if (HttpResponseHelper.requestPassed(response) && response.body.id) {
          return new CouponModel(response.body);
        }
        return null;
      })
    );
  }

  loadRestaurantStyle(restaurantSlug: string) {
    if (!restaurantSlug) {
      return;
    }

    const styleUrl = `${environment.restaurantsThemeUrl}/${restaurantSlug}.css`;
    this.scriptService.loadCssScript(styleUrl, 'restaurant-theme')
  }

  private updateRestaurantContext(restaurant: RestaurantModel) {
    this._restaurant = restaurant;
    this._restaurantSubject.next(restaurant);
    RestaurantService.currency = restaurant.currency;
    RestaurantService.currentRestaurant = restaurant;
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }

  createOrderRating(
    ratingData: any,
    transactionId
  ): Observable<HttpResponse<any>> {
    return this.api.post(
      `${API_ORDER_RATINGS_URL}/${transactionId}`,
      ratingData
    );
  }

  updateTableName(tableName: string) {
    this.tableNameSource.next(tableName);
  }

  getTables(restaurantId: number, queryParams: QueryParamsInterface = {}): Observable<HttpResponse<TableModel[]>> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'waiter,table_url';
    return this.api.get(`${API_RESTAURANTS_URL}/${restaurantId}/tables`, queryParams);
  }
}
