// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
@media (max-width: 768px) {
  :host form {
    width: 100%;
  }
}
@media (min-width: 992px) {
  :host .login-form .mat-form-field {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/reset-password/reset-password.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE;IACE,WAAA;EACJ;AACF;AAEE;EAEI;IACE,WAAA;EADN;AACF","sourcesContent":[":host {\n  width: 100%;\n\n  @media (max-width: 768px) {\n    form {\n      width: 100%;\n    }\n  }\n\n  @media (min-width: 992px) {\n    .login-form {\n      .mat-form-field {\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
