import { BaseModel } from './base.model';
import { OrderTypeModel } from './order-type.model';
import { CategoryModel, ProductModel, TagModel } from './product.model';
import { RestaurantModel } from './restaurant.model';
import * as moment from 'moment';
import { DEFAULT_PREPARATION_TIME } from '../globals';
import { HolidayModel } from '../../../../../admin/src/app/shared/models/holiday.model';

export class MenuModel extends BaseModel {
  date: string;
  restaurant: RestaurantModel;
  orderType: string;
  table: TableModel;
  menu: MenuWrapper[];
  preorderDates: PreorderDateModel[];
  previousVersion: any;
  todaysHolidays: HolidayModel[];
  private readonly TIME_STEP = 15;

  get initialValues() {
    return {
      menu: [],
      preorderDates: [],
      orderType: OrderTypeModel.TYPE_DELIVERY,
      previousVersion: {
        date: '',
        orderType: ''
      }
    };
  }

  get childModels() {
    return {
      menu: MenuWrapper,
      table: TableModel,
      restaurant: RestaurantModel,
      preorderDates: PreorderDateModel,
      todaysHolidays: HolidayModel
    };
  }

  get unsafeApiAttributes(): string[] {
    return [...super.unsafeApiAttributes, 'previousVersion', 'TIME_STEP'];
  }

  get totalBlockingTime(): number {
    if (this.orderType === OrderTypeModel.TYPE_DELIVERY) {
      return this.restaurant.preparationTime + this.restaurant.deliveryTime;
    }
    return this.restaurant.preparationTime;
  }

  get allProductTags(): TagModel[] {
    let allTags: TagModel[] = [];
    
    this.menu.forEach((category: MenuWrapper) => {
      category.products.forEach((product: ProductModel) => {
        product.tags.forEach((tag: TagModel) => {
          const foundIndex = allTags.findIndex((item) => item.id === tag.id);
          if (foundIndex === -1) {
            allTags.push(tag);
          }
        });
      });
    });

    return allTags;
  }

  get availableOrderType(): string {
    if (this.restaurant.hasOrderType(this.orderType)) {
      return this.orderType
    }
    if (this.restaurant.hasOrderType(OrderTypeModel.TYPE_DELIVERY)) {
      return OrderTypeModel.TYPE_DELIVERY;
    }
    if (this.restaurant.hasOrderType(OrderTypeModel.TYPE_TAKEAWAY)) {
      return OrderTypeModel.TYPE_TAKEAWAY;
    }
    if (this.restaurant.hasOrderType(OrderTypeModel.TYPE_IN_RESTAURANT)) {
      return OrderTypeModel.TYPE_IN_RESTAURANT;
    }
    return OrderTypeModel.TYPE_DELIVERY;
  }

  get canOrder(): boolean {
    return this.restaurant?.isActive && this.preorderDates?.length > 0;
  }

}

export class MenuWrapper extends BaseModel {
  category: CategoryModel;
  products: ProductModel[];

  get initialValues() {
    return {
      products: [],
    };
  }

  get childModels() {
    return {
      category: CategoryModel,
      products: ProductModel,
    };
  }
}

export class TableModel extends BaseModel {
  name: string;
  code: string;
  seatingCount: number;
}

export class PreorderDateModel extends BaseModel {
  date: string;
  time: Array<{ 'value': string, 'label': string }>;
}
