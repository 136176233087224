import { BaseModel } from './base.model';

export class DeliveryRuleModel extends BaseModel {
  id: number;
  zipcode: string;
  deliveryAmount: number;
  minOrderAmount: number;

  get unsafeApiAttributes(): string[] {
    return [];
  }

}
