import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as enLang from './modules/i18n/vocabs/en.json';
import * as itLang from './modules/i18n/vocabs/it.json';
import * as deLang from './modules/i18n/vocabs/de.json';
import * as frLang from './modules/i18n/vocabs/fr.json';
import * as srLang from './modules/i18n/vocabs/sr.json';
import { TranslationService } from './modules/i18n/translation.service';
import { environment } from './../environments/environment';
import { getWindow, getDocument, extend, ssrWindow } from 'ssr-window';
import { isPlatformBrowser } from '@angular/common';

declare var gtag;

extend(ssrWindow, {
  scrollTo: (x, y) => { },
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private router: Router,
    private translationService: TranslationService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.registerGoogleAnalytics();
  }

  ngOnInit() {
    // register translations
    this.translationService.loadTranslations(
      { lang: 'en', data: enLang },
      { lang: 'de', data: deLang },
      { lang: 'fr', data: frLang },
      { lang: 'it', data: itLang },
      { lang: 'sr', data: srLang },
      { lang: 'hr', data: srLang },
      { lang: 'me', data: srLang },
      { lang: 'ba', data: srLang },
    );
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          // scroll to top on every route change
          getWindow().scrollTo(0, 0);

          //configure Google Analytics tag manager
          gtag('config', environment.googleAnalyticsCode, {
            'page_path': event.urlAfterRedirects
          });
        }
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  private registerGoogleAnalytics() {
    if (environment.googleAnalyticsCode && isPlatformBrowser(this.platformId)) {
      const script = getDocument().createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsCode;
      getDocument().head?.prepend(script);
    }
  }
}

