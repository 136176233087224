import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageSnippet } from './image-snippet.model';


@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {

  selectedFile: ImageSnippet | undefined;

  constructor() {
  }

  @Input() imageUrl: string;
  @Input() placeholderImage = '/assets/img/dummy-user-image.png';
  @Input() stretched = false;
  @Input() coverBackground = false;
  @Input() label = 'Image Upload';
  @Output() image = new EventEmitter<any>();


  getPic() {
    if (this.selectedFile) {
      return `url('${this.selectedFile.src}')`;
    }
    if (this.imageUrl != '') {
      return `url('${this.imageUrl}')`;
    }
    return 'none';
  }

  deletePic() {
    this.imageUrl = '';
    this.selectedFile = undefined;
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.image.emit(this.selectedFile.file);
    });
    reader.readAsDataURL(file);
  }
}
