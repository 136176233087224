import { Injectable } from '@angular/core';
import { AuthService } from '../../modules/auth';
import { UserModel } from '../models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { TranslationService } from '../../modules/i18n/translation.service';
import { API, GUEST_CACHE_KEY, ORDER_STATUS } from '../globals';
import { QueryParamsInterface } from '../interfaces/query-params.interface';
import { RestaurantModel } from '../models/restaurant.model';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { AddressModel, CustomerAddressModel } from '../models/address.model';
import { OrderModel } from '../models/order.model';
import { HttpResponseHelper } from '../helpers/http-response.helper';
import { ToastManagementService } from './toast-management.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { RedirectHelper } from '../helpers/redirect.helper';
import { getWindow } from 'ssr-window';
import { LocalStorage } from './storage.service';

const expandParams = {
  expand: 'addresses,last_delivery_address_id,user_id'
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private USER = new BehaviorSubject<UserModel>(undefined);
  user = this.USER.asObservable();

  constructor(
    private authService: AuthService,
    private api: BaseApiService,
    private translationService: TranslationService,
    private toast: ToastManagementService,
    private storage: LocalStorage
  ) {
    AuthService.LOGIN.subscribe((redirect) => {
      this.initializeLanguage();
      if (redirect) {
        this.navigateToReturnUrl();
      }
    });

    TranslationService.TRANSLATED.subscribe(lang => {
      this.changeUserLanguage(lang);
    });
  }

  initializeLanguage() {
    return this.authService.getUserByToken(expandParams).subscribe((user: UserModel) => {
      if (user && user.language) {
        this.translationService.setLanguage(user.language);
      }
      this.USER.next(new UserModel(user));
    });
  }

  getUserFromApi(): void {
    this.authService.getUserByToken(expandParams).subscribe((user: UserModel) => {
      this.USER.next(new UserModel(user));
    });
  }

  private changeUserLanguage(lang: string): void {
    if (this.USER.value?.id) {
      this.api.put(`${API.USERS_URL}/me`, { language: lang }).subscribe();
    }
  }

  getRestaurants(queryParams: QueryParamsInterface = {}): Observable<RestaurantModel[]> {
    return this.api.get(`${API.USERS_URL}/restaurants`, queryParams).pipe(
      map(response => {
        if (HttpResponseHelper.requestPassed(response)) {
          return response.body.map(restaurant => new RestaurantModel(restaurant));
        }
      }
      )
    );
  }

  getOrders(queryParams: QueryParamsInterface = { expand: 'restaurant,restaurant.logo,restaurant.address,payment' }): Observable<OrderModel[]> {
    return this.api.get(`/orders/my`, queryParams).pipe(
      map(response => {
        if (HttpResponseHelper.requestPassed(response)) {
          return response.body.map(restaurant => new OrderModel(restaurant));
        }
      }
      )
    );
  }

  getOrdersWithPagination(queryParams: QueryParamsInterface = {}): Observable<HttpResponse<OrderModel[]>> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'restaurant,restaurant.logo,restaurant.address,payment';
    return this.api.get(`/orders/my`, queryParams);
  }

  cancelOrder(id: number): Observable<HttpResponse<OrderModel>> {
    return this.api.put(`/orders/${id}`, { status: ORDER_STATUS.canceled }).pipe(
      map(response => {
        const res = JSON.parse(JSON.stringify(response));
        if (HttpResponseHelper.requestPassed(res)) {
          res.body = new OrderModel(res.body);
          this.toast.showSuccess(_('Order Canceled'));
        }
        return res;
      }
      )
    );
  }

  updateMe(user: UserModel): Observable<HttpResponse<UserModel>> {
    const userApiData = new UserModel({}).toApi(user);
    return this.api.postFormData(`${API.USERS_URL}/me`, userApiData).pipe(
      map(response => {
        const res = JSON.parse(JSON.stringify(response));
        if (HttpResponseHelper.requestPassed(res)) {
          res.body = new UserModel(res.body);
          this.USER.next(res.body);
          this.toast.showSuccess(_('Profile Updated'));
        }
        return res;
      }
      )
    );
  }

  updateAddress(address: CustomerAddressModel): Observable<HttpResponse<CustomerAddressModel>> {
    return this.api.put(`${API.USERS_URL}/address/${address.id}`, address.toApi()).pipe(
      map(response => {
        const res = JSON.parse(JSON.stringify(response));
        if (HttpResponseHelper.requestPassed(res)) {
          res.body = new CustomerAddressModel(res.body);
          this.toast.showSuccess(_('Address Updated'));
        }
        return res;
      }
      )
    );
  }

  deleteAddress(addressId): Observable<HttpResponse<CustomerAddressModel>> {
    return this.api.delete(`${API.USERS_URL}/delivery-address/${addressId}`).pipe(
      map(response => {
        const res = JSON.parse(JSON.stringify(response));
        if (HttpResponseHelper.requestPassed(res)) {
          res.body = new CustomerAddressModel(res.body);
          this.toast.showSuccess(_('Address deleted'));
        }
        return res;
      }
      )
    );
  }

  setUsersRestaurant(restaurantId: number): void {
    this.api.put(`${API.USERS_URL}/restaurants`, { restaurant_id: restaurantId }).subscribe(res => {
      if (HttpResponseHelper.requestPassed(res)) {
        getWindow().location?.reload();
      }
    });
  }

  addNewAddress(address: AddressModel) {
    return this.api.post(`${API.USERS_URL}/address`, { ...address.toApi() }).pipe(
      map((response) => {
        const res = JSON.parse(JSON.stringify(response));
        if (HttpResponseHelper.requestPassed(res)) {
          res.body = new CustomerAddressModel(res.body);
          this.toast.showSuccess(_('New Address Added'));
        }
        return res;
      }
      )
    );
  }

  logout(): void {
    this.authService.logout();
    this.USER.next(new UserModel());
  }

  createGuestUser(user: UserModel): Observable<HttpResponse<UserModel>> {
    return this.api.post(`${API.USERS_URL}/guest`, user.toApi()).pipe(
      map(response => {
        const res = JSON.parse(JSON.stringify(response));
        if (HttpResponseHelper.requestPassed(res)) {
          res.body = new UserModel(res.body);
          this.USER.next(res.body);
        }
        return res;
      }
      )
    );
  }

  private navigateToReturnUrl(): void {
    const url = RedirectHelper.loginRedirectUrl;
    if (url && getWindow().location) {
      getWindow().location.href = url;
    }
  }

  setGuestUser(data) {
    this.storage.setItem(GUEST_CACHE_KEY, JSON.stringify(data));
  }

  loadGuestUser() {
    return JSON.parse(this.storage.getItem(GUEST_CACHE_KEY));
  }

  removeGuestUser() {
    this.storage.removeItem(GUEST_CACHE_KEY);
  }
}
