import { LAST_VISITED_RESTAURANT_SLUG, LAST_VISITED_RESTAURANT_TABLE_CODE, RESTAURANTS_URL } from '../globals';
import { LocalStorage } from '../services/storage.service';

export class LastVisitedRestaurantHelper {

  public static saveLastVisitedRestaurant(restaurantSlug: string, tableCode: string, storage: LocalStorage): void {
    storage.setItem(LAST_VISITED_RESTAURANT_SLUG, restaurantSlug);
    storage.setItem(LAST_VISITED_RESTAURANT_TABLE_CODE, tableCode || '');
  }

  public static checkPathnameAndSaveLastVisitedRestaurant(pathname: string, storage: LocalStorage): void {
    const params = pathname.split('/');

    if (params[1] != RESTAURANTS_URL) {
      return;
    }

    const restaurantSlug = params[2];
    const tableCode = params[3] ? params[3] : '';

    this.saveLastVisitedRestaurant(restaurantSlug, tableCode, storage);
  }

}
