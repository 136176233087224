// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-radius {
  border-radius: 50%;
}

div.position-relative {
  width: 110px;
  height: 110px;
  background-position: center;
  background-size: cover;
}

.streched {
  width: 265px;
}

.image-input-wrapper {
  width: 100%;
  height: 100%;
}

.image-input-wrapper.streched {
  background-size: cover;
}

.image-input {
  background-position: center;
}

.cover {
  background-size: cover;
}

.input-image {
  top: 45%;
  left: 45%;
  position: absolute;
}

i.position-absolute {
  top: -13px;
  right: -13px;
  font-size: 12px;
}

span.position-absolute {
  top: -13px;
  left: -16px;
  padding: 5px 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/image-upload/image-upload.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,QAAA;EACA,SAAA;EACA,kBAAA;AACF;;AAEA;EACE,UAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;EACA,4BAAA;AACF","sourcesContent":[".border-radius {\n  border-radius: 50%;\n}\n\ndiv.position-relative {\n  width: 110px;\n  height: 110px;\n  background-position: center;\n  background-size: cover;\n}\n\n.streched {\n  width: 265px;\n}\n\n.image-input-wrapper {\n  width: 100%;\n  height: 100%;\n}\n\n.image-input-wrapper.streched {\n  background-size: cover;\n}\n\n.image-input {\n  background-position: center;\n}\n\n.cover {\n  background-size: cover;\n}\n\n.input-image {\n  top: 45%;\n  left: 45%;\n  position: absolute;\n}\n\ni.position-absolute {\n  top: -13px;\n  right: -13px;\n  font-size: 12px;\n}\n\nspan.position-absolute {\n  top: -13px;\n  left: -16px;\n  padding: 5px 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
