import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayyoTermsAndConditionsComponent } from './payyo-terms-and-conditions/payyo-terms-and-conditions.component';
import { PayyoTermsAndConditionsRoutingModule } from './payyo-terms-and-conditions-routing.module';



@NgModule({
    declarations: [PayyoTermsAndConditionsComponent],
    exports: [
        PayyoTermsAndConditionsComponent
    ],
    imports: [
        CommonModule,
        PayyoTermsAndConditionsRoutingModule
    ]
})
export class PayyoTermsAndConditionsModule { }
