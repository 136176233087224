import { BaseModel } from './base.model';
import { AddressModel, CustomerAddressModel } from './address.model';

export class UserModel extends BaseModel {
  id: number;
  lastDeliveryAddressId?: number;
  username: string;
  password: string;
  newPassword?: string;
  passwordRepeat?: string;
  fullname: string;
  email: string;
  pic: string;
  role: string;
  isActive: 0 | 1;
  occupation: string;
  companyName: string;
  customerCode?: string;
  phone: string;
  // personal information
  firstName: string;
  imageFile?: File;
  lastName: string;
  website: string;
  // account information
  language: string;
  addresses: CustomerAddressModel[];
  isSeenWalkthrough?: 0 | 1;

  get childModels(): {} {
    return {
      addresses: CustomerAddressModel
    };
  }

  get initialValues(): {} {
    return {
      addresses: []
    };
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...(model ? {image_file: model.imageFile} : this.imageFile ? {image_file: this.imageFile} : {}),
    };
  }

  get image(): string {
    return this.pic ? this.pic : 'assets/img/dummy-user-image.png';
  }

  setUser(user: any) {
    this.id = user.id;
    this.username = user.username || '';
    this.password = user.password || '';
    this.firstName = user.first_name || '';
    this.lastName = user.last_name || '';
    this.fullname = user.full_name || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/img/dummy-user-image.jpg';
    this.role = user.role || '';
    this.language = user.language;
  }
}


