import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PrivacyPolicyRoutingModule } from './privacy-policy-routing.module';


@NgModule({
    declarations: [PrivacyPolicyComponent],
    exports: [
        PrivacyPolicyComponent
    ],
    imports: [
        CommonModule,
        PrivacyPolicyRoutingModule
    ]
})
export class PrivacyPolicyModule {
}
