import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissingTranslationHandler, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { LpTranslateParser, MyMissingTranslationHandler } from './missing-translations-handler';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      },
      parser: {
        provide: TranslateParser,
        useClass: LpTranslateParser
      }
    }),
  ],
  exports: [
    CommonModule,
    TranslateModule
  ],
})
export class TranslationModule {
}
