import { AddressModel } from './address.model';
import { BaseModel } from './base.model';

export class CompanyModel extends BaseModel {
  id: number;
  name: string;
  addressId: number;
  address?: AddressModel;
  hasCommission?: boolean;
  commissionType?: string;
  commissionAmount?: number;

  get childModels() {
    return {
      address: AddressModel,
    };
  }
}
