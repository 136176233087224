import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  modalRef: NgbModalRef;
  message = _('Are you sure?');
  confirmText = _('Confirm');
  declineText = _('Decline');

  showDeclineButton: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  confirm(): void {
    this.modalRef.close(true);
  }

  decline(): void {
    this.modalRef.close(false);
  }

}
