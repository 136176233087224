import { BaseModel } from './base.model';

export class WorkingHoursModel extends BaseModel {

  monday: WorkingHourModel[];
  tuesday: WorkingHourModel[];
  wednesday: WorkingHourModel[];
  thursday: WorkingHourModel[];
  friday: WorkingHourModel[];
  saturday: WorkingHourModel[];
  sunday: WorkingHourModel[];

  get childModels() {
    return {
      monday: WorkingHourModel,
      tuesday: WorkingHourModel,
      wednesday: WorkingHourModel,
      thursday: WorkingHourModel,
      friday: WorkingHourModel,
      saturday: WorkingHourModel,
      sunday: WorkingHourModel
    };
  }
}

class WorkingHourModel extends BaseModel {

  startTime: string;
  endTime: string;

}

