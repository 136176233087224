export const environment = {
  production: false,
  appVersion: 'v726dinego',
  USERDATA_KEY: 'authf649fc9a5f55',
  apiUrl: 'https://api.dinego-dev.singularity.is/v1',
  adminUrl: 'https://admin.dinego-dev.singularity.is',
  frontendUrl: 'https://dinego-dev.singularity.is',
  restaurantsThemeUrl: 'https://api.dinego-dev.singularity.is/assets/css/restaurant',
  clientId: 'dinego-dev',
  clientSecret: 'TbXwsSpr5EkLbddl4TcSCZr3qucQoti4',
  googleAnalyticsCode: '',
  googleApiKey: 'AIzaSyA5Z9XRAAscVgNwMyAU3waS11O4o2y1nhc',
  googleMapDefaultCenter: {lat: 47.38969, lng: 8.538081},
  googleMapDefaultZoomLevel: 16,
  datatransUrl: 'https://pay.sandbox.datatrans.com/upp/payment/js/datatrans-2.0.0.js',
  appLanguage: 'de',
  appCurrency: 'CHF',
  appLogo: 'assets/img/logos/logo-dark.svg',
  navbarLogo: 'assets/img/logos/logo-dark.svg',
  lightLogo: 'assets/img/logos/logo-light.svg'
};
