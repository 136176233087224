import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  title: String;
  message: String;

  cancelButtonText = _('Cancel');
  confirmButtonText = _('Confirm');

  accepted = new EventEmitter<boolean>();
  canceled = new EventEmitter<boolean>();

  modalRef: NgbModalRef;

  constructor() {
  }

  ngOnInit(): void {
  }

  accept(): void {
    if(this.modalRef){
      this.modalRef.close();
    }

    this.accepted.emit(true);
  }

  cancel(): void {
    if(this.modalRef){
      this.modalRef.close();
    }

    this.canceled.emit(true);
  }

}
