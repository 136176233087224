import { Injectable, OnDestroy, EventEmitter } from "@angular/core";
import { Observable, BehaviorSubject, of, Subscription, throwError } from "rxjs";
import { map, catchError, switchMap, finalize, shareReplay } from "rxjs/operators";
import { UserModel } from "../../../shared/models/user.model";
import { AuthModel } from "../_models/auth.model";
import { AuthHTTPService } from "./auth-http";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTAURANTS_URL } from "../../../shared/globals";
import { RedirectHelper } from "../../../shared/helpers/redirect.helper";
import { LastVisitedRestaurantHelper } from "../../../shared/helpers/last-visited-restaurant.helper";
import { getWindow } from 'ssr-window';
import { LocalStorage } from "../../../shared/services/storage.service";

const expandParams = {
  expand: "addresses,last_delivery_address_id",
};

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  static LOGIN = new EventEmitter<boolean>();
  static LOGOUT = new EventEmitter<boolean>();

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private readonly _getUserData = new Map<string, Observable<UserModel>>();

  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: LocalStorage
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken(expandParams).subscribe();
    this.unsubscribe.push(subscr);
  }

  login(
    email: string,
    password: string,
    redirect: boolean = false
  ): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthToLocalStorage(auth);
        AuthService.LOGIN.emit(redirect);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error("err", err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout(): void {
    this.storage.removeItem(this.authLocalStorageToken);
    AuthService.LOGOUT.emit(true);

    this.currentUserSubject.next(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();

    let url = this.router.url.split("/");

    if (url[1] != RESTAURANTS_URL && getWindow().location) {
      getWindow().location.href = RedirectHelper.logoutRedirectUrl;
    }
  }

  getUserByToken(params = {}): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    const cacheKey = auth.authToken;

    this.isLoadingSubject.next(true);
    this._getUserData.set(
      cacheKey,
      this.authHttpService.getUserByToken(auth.authToken, params).pipe(
        map((user: UserModel) => {
          if (user) {
            this.currentUserSubject = new BehaviorSubject<UserModel>(user);
          } else {
            LastVisitedRestaurantHelper.checkPathnameAndSaveLastVisitedRestaurant(getWindow().location?.pathname, this.storage);
            this.router.navigate(["auth", "login"]).then();
            this.logout();
          }
          return user;
        }),
        shareReplay(),
        catchError((err) => {
          LastVisitedRestaurantHelper.checkPathnameAndSaveLastVisitedRestaurant(getWindow().location?.pathname, this.storage);
          this.router.navigate(["auth", "login"], {
            queryParams: {
              returnUrl: getWindow().location?.href,
            },
          }).then();
          this.logout();
          return throwError(err);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      )
    );

    return this._getUserData.get(cacheKey);
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    let response: UserModel;
    return this.authHttpService.createUser(user).pipe(
      map((res) => {
        response = res;
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => {
        return response.id ? this.login(user.email, user.password) : of(null);
      }),
      catchError((err) => {
        console.error("err", err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resetPassword(
    password: string,
    passwordRepeat: string,
    token: string
  ): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(password, passwordRepeat, token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthToLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      this.storage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        this.storage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
