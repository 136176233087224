import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, ControlContainer, UntypedFormGroup } from "@angular/forms";
import { COUNTRIES } from "../../globals";
import { Subscription } from "rxjs";
import { AddressModel } from "../../models/address.model";
import { GoogleMapHelper } from "../../helpers/google-map-helper";
import { MapGeocoder, GoogleMap } from '@angular/google-maps';
import { CartService } from "../../services/cart.service";
import { RestaurantService } from "../../services/restaurant.service";
import { environment } from "../../../../environments/environment";

const ON_MAP_CHANGE = "on_map_change";

@Component({
  selector: "app-address-form",
  templateUrl: "./address-form.component.html",
  styleUrls: ["./address-form.component.scss"],
})
export class AddressFormComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleMap) map: GoogleMap;

  lat = RestaurantService.getRestaurantContext()?.address?.getLat() || environment.googleMapDefaultCenter.lat;
  lng = RestaurantService.getRestaurantContext()?.address?.getLng() || environment.googleMapDefaultCenter.lng;
  zoom = environment.googleMapDefaultZoomLevel;
  mapOptions: google.maps.MapOptions = {
    zoomControl: true,
    mapTypeControl: true,
    fullscreenControl: true,
    streetViewControl: false,
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{
          visibility: 'off'
        }]
      }
    ],
  };

  googleAutocompleteOptions = {
    fields: ["address_components", "geometry"],
    strictBounds: true,
    componentRestrictions: {
      country: [this.cartService.getCart().restaurant.address.countryCode],
    },
  };

  countries = COUNTRIES;
  private subscriptions: Subscription[] = [];
  private pinMoved = false;
  isMapReady = false;
  addressString: string;

  @Input() defaultLocation: { lat: number; lng: number };

  constructor(
    public controlContainer: ControlContainer,
    private cartService: CartService,
    private geocoder: MapGeocoder,
    private ngZone: NgZone,
  ) { }

  get formGroup(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  chooseLocation(e: any, type) {
    this.pinMoved = true;
    if (type === ON_MAP_CHANGE) {
      this.lat = this.map.getCenter().lat();
      this.lng = this.map.getCenter().lng();
    } else {
      this.lat = e.lat();
      this.lng = e.lng();
    }

    this.formGroup.get("lat").setValue(this.lat);
    this.formGroup.get("long").setValue(this.lng);
    this.getAddressFromCoordinates(this.lat, this.lng);
  }

  ngOnInit(): void {
    this.formGroup
      .get("countryCode")
      .setValue(this.cartService.getCart()?.restaurant?.address?.countryCode);
    if (this.defaultLocation?.lat && this.defaultLocation?.lng) {
      this.lat = this.defaultLocation.lat;
      this.lng = this.defaultLocation.lng;
    }

    for (const ctrl in this.formGroup.controls) {
      if (ctrl !== "lat" && ctrl !== "long") {
        const sub = this.formGroup.controls[ctrl].valueChanges.subscribe(() => {
          this.geocodeAddress();
        });
        this.subscriptions.push(sub);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private geocodeAddress(): void {
    if (this.formGroup.valid && !this.pinMoved) {
      this.geocoder
        .geocode({ address: new AddressModel(this.formGroup.value).asString })
        .subscribe((place) => {
          if (place?.results.length) {
            this.lat = place?.results[0].geometry.location.lat();
            this.lng = place?.results[0].geometry.location.lng();
            this.formGroup.get("lat").setValue(this.lat);
            this.formGroup.get("long").setValue(this.lng);
          }
        });
    }
  }

  private getAddressFromCoordinates(lat: number, lng: number): void {
    this.geocoder.geocode({ location: { lat, lng } }).subscribe((place) => {
      if (place.results?.length) {
        this.setEmptyFields(place.results[0]);
      }
    });
  }

  private setEmptyFields(place: any): void {
    const address = GoogleMapHelper.extractAddressComponents(place);
    if (this.ifEmpty(this.formGroup.get("street").value)) {
      this.formGroup.get("street").setValue(address.street);
    }
    if (this.ifEmpty(this.formGroup.get("state").value)) {
      this.formGroup.get("state").setValue(address.state);
    }
    if (this.ifEmpty(this.formGroup.get("city").value)) {
      this.formGroup.get("city").setValue(address.city);
    }
    if (this.ifEmpty(this.formGroup.get("zipcode").value)) {
      this.formGroup.get("zipcode").setValue(address.zipcode);
    }
    if (this.ifEmpty(this.formGroup.get("countryCode").value)) {
      this.formGroup.get("countryCode").setValue(address.countryCode);
    }
  }

  onMapReady(map: any) {
    map.addListener("dragend", () => {
      if (this.isMapReady) {
        this.ngZone.run(() => {
          this.chooseLocation(null, ON_MAP_CHANGE);
        });
      }
    });

    map.addListener("zoom_changed", () => {
      if (this.isMapReady) {
        this.ngZone.run(() => {
          this.chooseLocation(null, ON_MAP_CHANGE);
        });
      }
    });

    this.isMapReady = true;
  }

  handleAddressChange(event: AddressModel) {
    this.setEmptyFields(event);
    this.chooseLocation(event.geometry.location, "");
    this.isMapReady = true;
  }

  handleStreetInputChange(value: any) {
    this.addressString = value;
    if (this.addressString !== "") {
      this.isMapReady = false;
    }
  }

  clearAddressFields() {
    this.formGroup.get("street").setValue("");
    this.formGroup.get("city").setValue("");
    this.formGroup.get("state").setValue("");
    this.formGroup.get("zipcode").setValue("");
    this.formGroup.get("lat").setValue("");
    this.formGroup.get("long").setValue("");

    this.isMapReady = true;
  }

  private ifEmpty(control: AbstractControl): boolean {
    return !control.value || control.value == "";
  }
}
