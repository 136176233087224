// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-blur {
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.portrait-image {
  height: 65px;
  object-fit: contain;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.menu-article-img-box {
  height: 65px;
  width: 65px;
  flex-shrink: 0;
  border-radius: 3px;
  overflow: hidden;
}

.menu-article-img-box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/product-image/product-image.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,SAAA;EACA,QAAA;EACA,kBAAA;EACA,gCAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AACA;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AAEF","sourcesContent":[".image-blur {\n  filter: blur(1px);\n  -webkit-filter: blur(1px);\n}\n\n.portrait-image {\n  height: 65px;\n  object-fit: contain;\n  left: 50%;\n  top: 50%;\n  position: absolute;\n  transform: translate(-50%, -50%);\n}\n\n.menu-article-img-box {\n  height: 65px;\n  width: 65px;\n  flex-shrink: 0;\n  border-radius: 3px;\n  overflow: hidden;\n}\n.menu-article-img-box img {\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
