import { environment } from '../../../environments/environment';

export class ImageHelper {

  static createUrl(id: number, spec?: string): string {
    if (spec) {
      return `${environment.apiUrl}/image/${id}?spec=${spec}`;
    }
    return `${environment.apiUrl}/image/${id}`;
  }

}
