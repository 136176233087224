import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import * as moment from "moment";
import { MenuModel, PreorderDateModel } from "../../models/menu.model";
import { OrderTypeModel } from "../../models/order-type.model";
import { DEFAULT_PREPARATION_TIME } from "../../globals";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { CartService } from "../../services/cart.service";
import { LocalStorage } from "../../services/storage.service";
import { WalkthroughService } from "../../services/walkthrough.service";

const IS_ASAP = "is-asap";
const LAST_DATE_TIME = "last-date-time";

@Component({
  selector: "app-order-options",
  templateUrl: "./order-options.component.html",
  styleUrls: ["./order-options.component.scss"],
})
export class OrderOptionsComponent implements OnInit {
  private _menu: MenuModel = new MenuModel();
  private _selectedDate: moment.Moment;
  private readonly TIME_STEP = 15;

  @ViewChild('orderOptionsWalkthroughStartElement', { static: false }) orderOptionsWalkthroughStartElement: ElementRef;
  private walkthroughCheckInterval: any;
  private readonly CHECK_INTERVAL_MS = 1000;

  //days of week and months abbreviations labels, used only for generating translations
  daysOfWeek = [_("Mo"), _("Tu"), _("We"), _("Th"), _("Fr"), _("Sa"), _("Su")];
  months = [
    _("Jan"),
    _("Feb"),
    _("Mar"),
    _("Apr"),
    _("May"),
    _("Jun"),
    _("Jul"),
    _("Aug"),
    _("Sep"),
    _("Oct"),
    _("Nov"),
    _("Dec"),
  ];

  @Input() rowClass;
  @Input() isDisabled = false;
  @Input() isTypeInRestaurant = false;
  @Input() isWelcomePage: boolean | undefined;
  @Input() set menu(menu: MenuModel) {
    this._menu = menu;

    this.initOptions();
    this.initAvailableDates();
    this.initAvailableTimes();
  }

  @Output() optionsChanged: EventEmitter<MenuModel> =
    new EventEmitter<MenuModel>();
  @Output() timeChange: EventEmitter<MenuModel> = new EventEmitter<MenuModel>();
  @Output() asapChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  date: string;
  time: string;
  isAsap: boolean;
  orderType: string;
  availableTimes: TimeOption[] = [];
  availableDates: moment.Moment[] = [];
  isSwitchDateTimeEnabled: boolean;

  constructor(
    private storage: LocalStorage,
    private cartService: CartService,
    private walkthroughService: WalkthroughService
  ) { }

  ngOnInit(): void {
    this.initOptions();
    this.initAvailableDates();
    this.initAvailableTimes();
    this.checkOptions();
  }

  ngAfterViewInit(): void {
    this.walkthroughStartCheck();
  }

  walkthroughStartCheck(): void {
    if (this.walkthroughCheckInterval) {
      return;
    }

    this.walkthroughCheckInterval = setInterval(() => {
      if (this.orderOptionsWalkthroughStartElement) {
        this.walkthroughService.startOrderOptionsWalkthrough();
        this.stopWalkthroughCheck();
      }
    }, this.CHECK_INTERVAL_MS);
  }

  stopWalkthroughCheck(): void {
    if (this.walkthroughCheckInterval) {
      clearInterval(this.walkthroughCheckInterval);
    }

    this.walkthroughCheckInterval = null;
  }

  ngOnDestroy() {
    this.stopWalkthroughCheck();
  }

  get typeDelivery() {
    return OrderTypeModel.TYPE_DELIVERY;
  }

  get typeTakeaway() {
    return OrderTypeModel.TYPE_TAKEAWAY;
  }

  get typeInRestaurant() {
    return OrderTypeModel.TYPE_IN_RESTAURANT;
  }

  get restaurant() {
    return this._menu?.restaurant;
  }

  getMenu() {
    this.setSelectionOptions();
    this.optionsChanged.emit(this._menu);
  }

  onTimeChange() {
    this.setSelectionOptions();
    this.timeChange.emit(this._menu);
  }

  hasOrderType(type: string) {
    return this._menu?.restaurant?.hasOrderType(type);
  }

  hasOrderTypes() {
    return this._menu?.restaurant?.activeOrderTypes?.length ? true : false;
  }

  hasOneOrderTypes() {
    return !this.hasOrderType(OrderTypeModel.TYPE_DELIVERY) || !this.hasOrderType(OrderTypeModel.TYPE_TAKEAWAY);
  }

  hasPreorder() {
    return this._menu.preorderDates?.length > 0;
  }

  hasAsap() {
    const today = moment().format("YYYY-MM-DD");
    return (
      !!this._menu?.preorderDates?.find((o) => o.date == today) &&
      this._menu.restaurant?.isWorking
    );
  }

  showPreorderOptions() {
    return (
      (!this.isAsap || !this.hasAsap()) &&
      (this.availableDates?.length || this.availableTimes?.length)
    );
  }

  emptyCart() {
    this.cartService.emptyCart();
    this.isSwitchDateTimeEnabled = true;
  }

  private initOptions() {
    this.isSwitchDateTimeEnabled = this.cartService.isCartEmpty();
    this.isAsap = this.hasAsap() ? this.getAsapFromLocalStorage() : false;
    this.saveAsapToLocalStorage();
    this.asapChange.emit(this.isAsap);
    this.orderType = this._menu.availableOrderType;
    const lastSavedDate = this.getLastDateTimeFromLocalStorage();

    this._selectedDate = lastSavedDate
      ? moment(lastSavedDate)
      : this._menu?.date
        ? moment(this._menu.date)
        : moment();
    this.date = this._selectedDate.format("YYYY-MM-DD");
    this.time = this._selectedDate.format("HH:mm");
  }

  private checkOptions(): void {
    if (this._menu.orderType !== this.orderType) {
      this.getMenu();
    }
  }

  private initAvailableDates() {
    this.availableDates = [];
    this._menu.preorderDates.forEach((i: PreorderDateModel) => {
      this.availableDates.push(moment(i.date));
    });
    if (
      !this._menu.preorderDates.find(
        (o: PreorderDateModel) => o.date == this.date
      ) &&
      this.availableDates.length
    ) {
      this.date = this.availableDates[0].format("YYYY-MM-DD");
      this.saveLastDateTimeToLocalStorage();
    }
  }

  private initAvailableTimes() {
    const date = this._menu.preorderDates.find(
      (o: PreorderDateModel) => o.date == this.date
    );
    this.availableTimes = [];
    if (date) {
      this.availableTimes = this.getAvailableTimesForDate(date.date);
    }
    this.availableTimes = this.sortTimes(this.availableTimes);
    this.availableTimes = this.filterTimes(this.availableTimes, date);
    if (!this.availableTimes.find((o: TimeOption) => o.value == this.time)) {
      this.time = this.availableTimes[0]?.value;
      this.saveLastDateTimeToLocalStorage();
    }
    this.isTypeInRestaurant && this.onTimeChange();
  }

  private setSelectionOptions() {
    this.saveAsapToLocalStorage();
    this.saveLastDateTimeToLocalStorage();
    this.asapChange.emit(this.isAsap);
    this._menu.previousVersion.orderType = this._menu.orderType;
    this._menu.previousVersion.date = this._menu.date;
    const dateString =
      this.date && this.time
        ? `${this.date} ${this.time}`
        : `${this.date} 00:00`;
    this._selectedDate = moment(dateString);
    this._menu.orderType = this.orderType;
    this._menu.date = !this.isAsap
      ? this._selectedDate.format("YYYY-MM-DD HH:mm")
      : "";
  }

  private filterTimes(times: any[], date): any[] {
    const now = moment();
    const prepTime = this._menu.totalBlockingTime
      ? this._menu.totalBlockingTime
      : DEFAULT_PREPARATION_TIME;
    if (this.date == now.format("YYYY-MM-DD")) {
      now.add(prepTime, "minute");
      times = times.filter((o) => o.value > now.format("HH:mm"));
    }
    return times;
  }

  private getAvailableTimesForDate(date: string): any[] {
    const times = [];
    const dayOfWeek = moment(date).format("dddd").toLowerCase();
    const workingHours = this._menu.restaurant.workingHours[dayOfWeek];
    const prepTime = this._menu.totalBlockingTime ? this._menu.totalBlockingTime : DEFAULT_PREPARATION_TIME;

    for (const workingHour of workingHours) {
      const shouldAddPrepTimeOffset = this.shouldAddPrepTimeOffset(workingHour, date);
      const startTime = moment(workingHour.startTime, "HH:mm");

      if (shouldAddPrepTimeOffset) {
        startTime.add(prepTime, "minutes");
      }

      const nextQuarterHour = this.getNextQuarterHour(startTime.format("HH:mm"));
      times.push(
        ...this.getRangeToChunkArray(nextQuarterHour, workingHour.endTime)
      );
    }
    return times;
  }

  private shouldAddPrepTimeOffset(workingHour, date) {
    if (workingHour.startTime != '00:00') {
      return true;
    }

    const previousDayOfWeek = moment(date).add(-1, 'days').format("dddd").toLowerCase();
    const previousDayWorkingHours = this._menu.restaurant.workingHours[previousDayOfWeek];

    if (!previousDayWorkingHours) {
      return true;
    }

    for (const previousDayWorkingHour of previousDayWorkingHours) {
      if (previousDayWorkingHour.endTime == '00:00') {
        return false;
      }
    }

    return true;
  }

  private getNextQuarterHour(time: string): string {
    const momentTime = moment(time, "HH:mm");
    const minutes = momentTime.minutes();
    const remainder = minutes % 15;
    if (remainder !== 0) {
      momentTime.add(15 - remainder, "minutes");
    }
    return momentTime.format("HH:mm");
  }

  private getRangeToChunkArray(fromTime: string, toTime: string): any[] {
    const times = [];
    const startMoment = moment(fromTime, "HH:mm");
    const endMoment = toTime == '00:00' ? moment(toTime, "HH:mm").add(1, 'days') : moment(toTime, "HH:mm");

    if (startMoment <= endMoment) {
      do {
        times.push({
          value: startMoment.format("HH:mm"),
          label: `${startMoment.format("HH:mm")}`,
        });
        startMoment.add(15, "minutes");
      } while (startMoment <= endMoment && startMoment.format("HH:mm") != '00:00');
    }
    return times;
  }

  private sortTimes(times: any[]): any[] {
    times.sort((a, b) => {
      const keyA = moment(a.value, "HH:mm");
      const keyB = moment(b.value, "HH:mm");
      if (keyA.isBefore(keyB)) {
        return -1;
      }
      if (!keyA.isBefore(keyB)) {
        return 1;
      }
      return 0;
    });
    return times;
  }

  private saveAsapToLocalStorage(): void {
    this.storage.setItem(IS_ASAP, JSON.stringify(this.isAsap));
  }

  private getAsapFromLocalStorage(): boolean {
    if (this.storage.getItem(IS_ASAP) === null) {
      return JSON.parse("true");
    } else {
      return JSON.parse(this.storage.getItem(IS_ASAP)) === true;
    }
  }

  private saveLastDateTimeToLocalStorage(): void {
    const dateString =
      this.date && this.time
        ? `${this.date} ${this.time}`
        : `${this.date} 00:00`;
    this.storage.setItem(LAST_DATE_TIME, JSON.stringify(dateString));
  }

  private getLastDateTimeFromLocalStorage(): string {
    return JSON.parse(this.storage.getItem(LAST_DATE_TIME));
  }
}

interface TimeOption {
  label: string;
  value: string;
}
