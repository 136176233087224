import { Injectable } from '@angular/core';
import * as introJs from "intro.js/intro.js";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "./user.service";
import { UserModel } from "../models/user.model";
import { RestaurantService } from "./restaurant.service";

@Injectable({
  providedIn: 'root'
})
export class WalkthroughService {
  private walkthrough: introJs.IntroJs;
  private currentUser: UserModel;

  private ORDER_OPTIONS_WALKTHROUGH_KEY = 'order-options-walkthrough-completed';
  private RESTAURANT_MENU_WALKTHROUGH_KEY = 'restaurant-menu-walkthrough-completed';
  private WALKTHROUGH_KEY = 'walkthrough-completed';

  constructor(
    private translate: TranslateService,
    private userService: UserService,
  ) {
    this.walkthrough = introJs();
    this.userService.user.subscribe((user) => {
      if (user && user.id) {
        if (!!user.isSeenWalkthrough) {
          this.completeWalkthrough(this.WALKTHROUGH_KEY);
          return;
        }

        if (this.isWalkthroughCompleted(this.WALKTHROUGH_KEY)) {
          user.isSeenWalkthrough = 1;
          this.userService.updateMe(user).subscribe();
        }

        this.currentUser = user;
      }
    });
  }

  startOrderOptionsWalkthrough() {
    if (!this.isWalkthroughVisible() || this.isOrderOptionsWalkthroughCompleted()) {
      return;
    }

    const steps = [];

    const step1Element = document.querySelector('#order-step-1');
    if (step1Element) {
      steps.push(
        this.createWalkthroughStep(
          'WALKTHROUGH.ORDER_TYPE.TITLE',
          'WALKTHROUGH.ORDER_TYPE.TEXT',
          step1Element
        ));
    }

    const step2Element = document.querySelector('#order-step-2');
    if (step2Element) {
      steps.push(
        this.createWalkthroughStep(
          'WALKTHROUGH.DATE_SELECTION.TITLE',
          'WALKTHROUGH.DATE_SELECTION.TEXT',
          step2Element
        ));
    }

    const step3Element = document.querySelector('#order-step-3');
    if (step3Element) {
      steps.push(
        this.createWalkthroughStep(
          'WALKTHROUGH.DATE_SELECTION.TITLE',
          'WALKTHROUGH.DATE_SELECTION.TEXT',
          step3Element
        ));
    }

    const step4Element = document.querySelector('#order-step-4');
    if (step4Element) {
      steps.push(
        this.createWalkthroughStep(
          'WALKTHROUGH.GO_TO_MENU.TITLE',
          'WALKTHROUGH.GO_TO_MENU.TEXT',
          step4Element,
          step4Element.getAttribute('walkthrough-id') === 'welcome' ? 'top' : 'right'
        ));
    }

    this.setWalkthroughOptions(steps, () => {
      this.completeWalkthrough(this.ORDER_OPTIONS_WALKTHROUGH_KEY);
      this.handleEveryWalkthroughCompleted();
    });
  }

  startRestaurantMenuWalkthrough(element) {
    if (!this.isWalkthroughVisible() || this.isRestaurantMenuWalkthroughCompleted()) {
      return;
    }

    this.setWalkthroughOptions([
        this.createWalkthroughStep(
          'WALKTHROUGH.RESTAURANT_MENU.TITLE',
          'WALKTHROUGH.RESTAURANT_MENU.TEXT',
          element,
          'left'
        )
      ],
      () => {
        if (!this.isOrderOptionsWalkthroughCompleted()) {
          element.click();
        }

        this.completeWalkthrough(this.RESTAURANT_MENU_WALKTHROUGH_KEY);
        this.handleEveryWalkthroughCompleted();
      });
  }

  private setWalkthroughOptions(steps: any[], onComplete: () => void) {
    let completed = false;

    const handleExit = () => {
      if (completed) {
        return;
      }
      onComplete();
    };

    this.walkthrough
      .oncomplete(() => {
        completed = true;
        onComplete();
      })
      .setOptions(
        {
          steps: steps,
          exitOnOverlayClick: false,
          prevLabel: _(this.translate.instant('Back')),
          nextLabel: _(this.translate.instant('Next')),
          doneLabel: _(this.translate.instant('Ok, got it!')),
        })
      .onexit(handleExit as any)
      .start();
  }

  private createWalkthroughStep(titleKey: string, textKey: string, element: any, position: string = 'right') {
    return {
      title: _(this.translate.instant(titleKey)),
      element: element,
      intro: _(this.translate.instant(textKey)),
      position: position
    };
  }

  private isWalkthroughVisible() {
    return !!RestaurantService.getRestaurantContext()?.hasWalkthrough && !this.isEveryWalkthroughCompleted();
  }

  private isRestaurantMenuWalkthroughCompleted() {
    return this.isWalkthroughCompleted(this.RESTAURANT_MENU_WALKTHROUGH_KEY);
  }

  private isOrderOptionsWalkthroughCompleted() {
    return this.isWalkthroughCompleted(this.ORDER_OPTIONS_WALKTHROUGH_KEY);
  }

  isEveryWalkthroughCompleted() {
    return this.isWalkthroughCompleted(this.WALKTHROUGH_KEY);
  }

  private handleEveryWalkthroughCompleted() {
    if (!this.isRestaurantMenuWalkthroughCompleted() || !this.isOrderOptionsWalkthroughCompleted()) {
      return;
    }

    this.removeWalkthrough(this.RESTAURANT_MENU_WALKTHROUGH_KEY);
    this.removeWalkthrough(this.ORDER_OPTIONS_WALKTHROUGH_KEY);

    this.completeWalkthrough(this.WALKTHROUGH_KEY);
    this.updateCurrentUserWalkthroughSeen();
  }

  private updateCurrentUserWalkthroughSeen() {
    if (this.currentUser && !this.currentUser.isSeenWalkthrough) {
      this.currentUser.isSeenWalkthrough = 1;
      this.userService.updateMe(this.currentUser).subscribe();
    }
  }

  private isWalkthroughCompleted(name: string): boolean {
    return !!JSON.parse(localStorage.getItem(name));
  }

  private completeWalkthrough(name: string) {
    localStorage.setItem(name, JSON.stringify(true));
  }

  private removeWalkthrough(name: string) {
    localStorage.removeItem(name);
  }

}
