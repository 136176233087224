import { BaseModel } from "./base.model";
import { ImageModel } from "./image.model";
import { ImageHelper } from "../helpers/image.helper";
import {
  AvailabilityHourModel,
  AvailabilityHoursModel,
} from "./availability-hours.model";
import * as moment from "moment";
import { CartModel } from "./cart.model";

export class ProductModel extends BaseModel {
  static readonly TYPE_FOOD: string = "food";
  static readonly TYPE_BEVERAGE: string = "beverage";

  id: number;
  categoryId: number;
  name: string;
  description: string;
  addonsTitle: string;
  type: string;
  price: number;
  maxAddon: number;
  isActive: boolean;
  image: ImageModel;
  category: CategoryModel;
  tags: TagModel[];
  addons: AddonModel[];
  availability: WeeklyAvailabilityModel;

  imageFile?: File;
  defaultImageUrl = "/assets/img/placeholder-meal.jpg";

  get initialValues() {
    return {
      tags: [],
      addons: [],
      isActive: true,
      type: ProductModel.TYPE_FOOD,
      availability: new WeeklyAvailabilityModel(),
    };
  }

  get childModels() {
    return {
      tags: TagModel,
      addons: AddonModel,
      category: CategoryModel,
      availability: WeeklyAvailabilityModel,
    };
  }

  getImageUrl(specs = "") {
    if (this.image && this.image.url) {
      if (this.image && this.image.id) {
        return ImageHelper.createUrl(this.image.id, specs);
      }
    }
    return this.defaultImageUrl;
  }

  get typeFood() {
    return ProductModel.TYPE_FOOD;
  }

  get typeBeverage() {
    return ProductModel.TYPE_BEVERAGE;
  }

  get hasAddons(): boolean {
    return this.addons.length > 0;
  }

  set hasAddons(b: boolean) {
    if (!b) {
      this.addons = [];
    }
  }

  addAddon(addon: AddonModel) {
    this.addons.push(addon);
  }

  removeAddon(addon: AddonModel) {
    this.addons = this.addons.filter((item: AddonModel) => {
      if (addon.id) {
        return addon.id !== item.id;
      }

      return addon.name !== item.name || addon.price !== item.price;
    });
  }
}

export class CategoryModel extends BaseModel {
  id: number;
  parentId: number;
  name: string;
  description: string;
  sort: number;
  level: number;
  image: ImageModel;
  parent?: CategoryModel;
  availabilityHours?: AvailabilityHoursModel;
  availabilityHoursOnAllLevels?: AvailabilityHoursModel;

  children?: CategoryModel[] = [];

  defaultImageUrl = "/assets/img/placeholder-category.jpg";

  get unsafeApiAttributes() {
    return ["availabilityHoursOnAllLevels"];
  }

  get initialValues() {
    return {
      sort: 0,
      level: 0,
    };
  }

  get childModels(): {} {
    return {
      image: ImageModel,
      availabilityHours: AvailabilityHoursModel,
    };
  }

  getImageUrl(specs = "") {
    if (this.image && this.image.url) {
      if (this.image && this.image.id) {
        return ImageHelper.createUrl(this.image.id, specs);
      }
    }
    return this.defaultImageUrl;
  }

  public isAvailableForDayAndHour(date: string, blockingTime: number): boolean {
    let availabilityHours = new AvailabilityHoursModel(
      this.availabilityHoursOnAllLevels
    );

    if (!availabilityHours.hasAvailabilityHours) {
      return true;
    }

    let dateTime = date
      ? moment(date, "YYYY-MM-DD HH:mm").locale("en")
      : moment().locale("en");

    let dayOfWeek = dateTime.format("dddd").toLowerCase();

    if (!availabilityHours.hasAvailabilityHoursForDay(dayOfWeek)) {
      return false;
    }

    const dateString = dateTime.format("YYYY-MM-DD");

    for (let i = 0; i < availabilityHours[dayOfWeek].length; i++) {
      const hour: AvailabilityHourModel = availabilityHours[dayOfWeek][i];

      let timeFrom = moment(dateString + " " + hour.startTime).subtract(
        1,
        "second"
      );
      let timeTo = moment(dateString + " " + hour.endTime).add(1, "second");

      if (!date) {
        timeTo.subtract(blockingTime, "minutes");
      }

      if (dateTime.isBetween(timeFrom, timeTo)) {
        return true;
      }
    }

    return false;
  }
}

export class TagModel extends BaseModel {
  id: number;
  restaurantId: number;
  name: string;
  icon: string;
  type: string;

  get unsafeApiAttributes() {
    return [];
  }
}

export class AddonModel extends BaseModel {
  id: number;
  name: string;
  price: number;

  get unsafeApiAttributes(): string[] {
    return ["name", "price"];
  }
}

export class WeeklyAvailabilityModel extends BaseModel {
  onMon: boolean;
  onTue: boolean;
  onWed: boolean;
  onThu: boolean;
  onFri: boolean;
  onSat: boolean;
  onSun: boolean;
}
