import { BaseModel } from './base.model';
import { ImageHelper } from '../helpers/image.helper';

export class ImageModel extends BaseModel {
  id: number;
  baseUrl: string;
  url: string;
  width: number;
  height: number;

  getThumb(spec = ''): string {
    return ImageHelper.createUrl(this.id, spec);
  }
}
