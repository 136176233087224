import { BaseModel } from './base.model';
import { AddonModel } from './product.model';
import { AddressModel } from './address.model';
import { UserModel } from './user.model';
import { ORDER_STATUS, ORDER_TYPE, PAYMENT_METHODS } from '../globals';
import { RestaurantModel } from './restaurant.model';
import * as moment from 'moment';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TableModel } from './menu.model';
import { CouponModel } from './coupon.model';

export class OrderModel extends BaseModel {
  id?: number;
  tableId?: number;
  address?: AddressModel;
  addressId?: number;
  restaurantId?: number;
  restaurant?: RestaurantModel;
  customer?: CustomerModel;
  customerId?: number;
  uuid: string;
  orderType: string;
  payment: PaymentModel;
  deliverAt: string;
  deliverAtTime: string;
  deliverAtDate: string;
  deliveredAt: string;
  note?: string;
  orderAmount: number;
  tipAmount: number;
  additionalAmount?: number;
  deliveryAmount?: number;
  discountAmount?: number;
  totalBeforeTax?: number;
  totalTax?: number;
  totalAmount: number;
  status: string;
  paymentStatus: string;
  canBeCanceled: boolean;
  orderItems: OrderItem[];
  table: TableModel;
  coupon?: CouponModel;

  get childModels(): {} {
    return {
      orderItems: OrderItem,
      address: AddressModel,
      customer: UserModel,
      restaurant: RestaurantModel,
      payment: PaymentModel,
      table: TableModel,
      coupon: CouponModel,
    };
  }

  get unsafeApiAttributes() {
    return ['id', 'deliveredAt', 'deliverAt', 'orderAmount', 'totalAmount', 'status', 'paymentStatus', 'restaurant', 'tip', 'total', 'coupon', 'tableName', 'restaurantSlug', 'uuid'];
  }

  get isCanceled(): boolean {
    return this.status === ORDER_STATUS.canceled;
  }

  get isFinished(): boolean {
    return this.status === ORDER_STATUS.finished;
  }

  get isReady(): boolean {
    return this.status === ORDER_STATUS.ready;
  }

  get isPreparing(): boolean {
    return this.status === ORDER_STATUS.preparing;
  }

  get isAccepted(): boolean {
    return this.status === ORDER_STATUS.accepted;
  }

  get isPending(): boolean {
    return this.status === ORDER_STATUS.pending;
  }

  get isInRestaurant(): boolean {
    return this.orderType === ORDER_TYPE.inRestaurant;
  }

  get isDelivery(): boolean {
    return this.orderType === ORDER_TYPE.delivery;
  }

  get dateTime(): string {
    if (!this.deliverAtDate || !this.deliverAtTime) {
      return _('ASAP');
    }
    return `${this.date} ${this.time}`;
  }

  get date(): string {
    return moment(this.deliverAtDate).format('DD.MM.YYYY');
  }

  get time(): string {
    return moment(this.deliverAtDate + ' ' + this.deliverAtTime).format('HH:mm');
  }

  get itemTotal(): number {
    let total = 0;
    this.orderItems.forEach(item => total += parseFloat(String(item.totalAmount)));
    return total;
  }

  get noVatTotal(): number {
    let total = this.itemTotal;

    if (this.additionalAmount) {
      total += parseFloat(this.additionalAmount.toString());
    }

    if (this.deliveryAmount) {
      total += parseFloat(this.deliveryAmount.toString());
    }

    if (this.discountAmount) {
      total -= parseFloat(this.discountAmount.toString());
    }

    return total - this.totalTax;
  }

  get deliverAtFormatted(): string {
    return this.deliverAtDate ? moment(this.deliverAtDate).format('DD.MM.YYYY') + ' ' + this.deliverAtTime.slice(0, -3) : _('ASAP');
  }

}

export class OrderItem extends BaseModel {
  orderId: number;
  productId: number;
  name: string;
  type: string;
  note: string;
  quantity: number;
  unitPrice: number;
  addonAmount: number;
  totalAmount: number;
  addons: AddonModel[];

  get childModels(): {} {
    return {
      addons: AddonModel
    };
  }

  get addonString() {
    return this.addons.map(o => o.name).join(', ');
  }

  get unsafeApiAttributes(): string[] {
    return ['total', 'totalPrice', 'addonPrice', 'name', 'unitPrice', 'image', 'defaultImageUrl'];
  }
}

export class CustomerModel extends BaseModel {
  id: number;
  addressId?: number;
  firstName: string;
  lastName: string;
  address?: AddressModel;
  email: string;
  phone: string;

  get childModels(): {} {
    return {
      address: AddressModel
    };
  }
}

export class PaymentModel extends BaseModel {
  orderId: number;
  transactionId: string;
  transactionInitTime: number;
  transactionTime: number;
  referenceNumber: string;
  paymentType: string;
  paymentMethod: string;
  currency: string;
  feeAmount: number;
  commissionAmount: number;
  restaurantAmount: number;
  totalAmount: number;
  authCode: string;
  acquirerAuthCode: string;
  paymentOptionNumber: string;
  paymentOptionBrand: string;
  responseCode: string;
  responseMessage: string;
  status: string;

  get transactionDateObject() {
    if (!this.transactionTime) {
      return null;
    }

    return new Date(this.transactionTime * 1000);
  }

  get transactionInitDateObject() {
    if (!this.transactionTime) {
      return null;
    }

    return new Date(this.transactionTime * 1000);
  }

  get paymentMethodObj(): any {
    return this.paymentMethod ? PAYMENT_METHODS.find(method => method.value == this.paymentMethod) : {};
  }
}

