import { AddressModel } from './address.model';
import { OrderTypeModel } from './order-type.model';
import { ImageModel } from './image.model';
import { CompanyModel } from './company.model';
import { WorkingHoursModel } from './working-hours.model';
import { BaseModel } from './base.model';
import { ImageHelper } from '../helpers/image.helper';
import { DeliveryRuleModel } from './delivery-rule.model';

export class RestaurantModel extends BaseModel {
  id: number;
  companyId: number;
  company?: CompanyModel;
  addressId: number;
  name: string;
  currency: string;
  slug: string;
  description?: string;
  email?: string;
  phone?: string;
  contactName?: string;
  inactiveMessage?: string;
  declaration?: string;
  isActive?: 0 | 1;
  isWorking?: boolean;
  isRequireGuestEmail?: boolean;
  logoId?: number;
  preparationTime?: number;
  deliveryTime?: number;
  deliveryRadius?: number;
  logoFile?: File;
  coverFile?: File;
  coverId?: number;
  isFranchise?: boolean;
  address?: AddressModel;
  orderTypes?: OrderTypeModel[];
  workingHours?: WorkingHoursModel;
  logo: ImageModel;
  cover: ImageModel;
  defaultLogoUrl = '/assets/img/placeholder-restaurant.jpg';
  defaultCoverUrl = '/assets/img/placeholder-restaurant-cover.jpg';
  deliveryRules?: DeliveryRuleModel[];
  hasWalkthrough?: 0 | 1;

  get initialValues() {
    return {
      orderTypes: [],
      isActive: true,
    };
  }

  get childModels() {
    return {
      company: CompanyModel,
      address: AddressModel,
      orderTypes: OrderTypeModel,
      workingHours: WorkingHoursModel,
      logo: ImageModel,
      cover: ImageModel,
      deliveryRules: DeliveryRuleModel
    };
  }

  getOrderTypeByKey(key: string) {
    return this.orderTypes.find(o => o.orderType === key);
  }

  getLogoUrl(spec = '') {
    if (this.logo && this.logo.id) {
      return ImageHelper.createUrl(this.logo.id, spec);
    }
    return this.defaultLogoUrl;
  }

  getCoverUrl(spec = '') {
    if (this.cover && this.cover.id) {
      return ImageHelper.createUrl(this.cover.id, spec);
    }
    return this.defaultCoverUrl;
  }

  get activeOrderTypes() {
    return this.orderTypes.filter((o: OrderTypeModel) => o.isActive);
  }

  hasOrderType(type: string) {
    return !!this.activeOrderTypes.find((o) => o.orderType == type);
  }

  getDeliveryRuleForZipcode(zipcode: string): DeliveryRuleModel {
    return this.deliveryRules.find(rule => rule.zipcode === zipcode);
  }

}
