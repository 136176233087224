import { BaseModel } from "./base.model";
import { ADDRESS_TYPES, COUNTRIES } from "../globals";

export class AddressModel extends BaseModel {
  id: number;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  countryCode: string;
  lat: string;
  long: string;

  get asString(): string {
    return [this.line1, this.line2].filter((el) => !!el).join(", ");
  }

  get asHtml(): string {
    return [this.line1, this.line2].filter((el) => !!el).join("<br>");
  }

  get line1(): string {
    return `${this.street}`.trim();
  }

  get line2(): string {
    return `${this.zipcode} ${this.city}`.trim();
  }

  get line3(): string {
    return `${this.state ? this.state + "," : ""} ${this.country}`.trim();
  }

  get country(): string {
    return this.countryCode
      ? COUNTRIES.find((o) => o.code == this.countryCode).name
      : "";
  }

  getLat() {
    return parseFloat(this.lat);
  }

  getLng() {
    return parseFloat(this.long);
  }
}

export class CustomerAddressModel extends AddressModel {
  id: number;
  type: string;
  address: AddressModel;
  isDeleted: 0 | 1;

  get childModels(): {} {
    return {
      address: AddressModel,
    };
  }

  get typeObject() {
    return ADDRESS_TYPES.find((t) => t.key === this.type);
  }
}
