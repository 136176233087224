import { AddressModel } from "./address.model";
import { BaseModel } from "./base.model";
import { AddonModel } from "./product.model";
import { ORDER_TYPE, PAYMENT_METHOD } from "../globals";
import { RestaurantModel } from "./restaurant.model";
import { NumberHelper } from "../helpers/number.helper";
import { OrderTypeModel, PaymentMethodModel } from "./order-type.model";
import * as moment from "moment";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { CouponModel } from "./coupon.model";
import { ImageModel } from "./image.model";
import { ImageHelper } from "../helpers/image.helper";

export class CartModel extends BaseModel {
  restaurantId?: number;
  tableId?: number;

  restaurantSlug: string;
  tableCode: string;
  tableName: string;
  couponCode: string;
  coupon: CouponModel;
  orderType: string;
  note: string;
  deliverAt: string;
  tip: number;
  address: AddressModel;
  cart: OrdersModel[];
  restaurant: RestaurantModel;
  total: number;

  get deliverAtFormatted(): string {
    return this.deliverAt
      ? moment(this.deliverAt).format("DD.MM.YYYY HH:mm")
      : _("ASAP");
  }

  get deliverAtDate(): string {
    return this.deliverAt ? moment(this.deliverAt).format("DD.MM.YYYY") : "";
  }

  get deliverAtTime(): string {
    return this.deliverAt ? moment(this.deliverAt).format("HH:mm") : "";
  }

  get isDelivery(): boolean {
    return this.orderType === ORDER_TYPE.delivery;
  }

  get isTakeaway(): boolean {
    return this.orderType === ORDER_TYPE.takeaway;
  }

  get itemTotal(): number {
    return NumberHelper.roundToTwoDecimals(
      this.cart.reduce((total, group) => {
        const groupItemTotal = group.orderItems.reduce((groupTotal, item) => {
          return groupTotal + item.totalPrice;
        }, 0);
        return total + groupItemTotal;
      }, 0)
    );
  }

  get hasItems(): boolean {
    return this.cart?.some((group) => group.orderItems.length > 0) ?? false;
  }

  get vatPrice(): number {
    const totalTaxable = this.itemTotal + this.deliveryCost + this.additionalCost - this.discount;
    const vatPercent = this.vatPercent / 100;

    return totalTaxable * vatPercent / (1 + vatPercent);
  }

  get vatPercent(): number {
    return parseFloat(
      this.restaurant.getOrderTypeByKey(this.orderType).vatPercent || "0"
    );
  }

  get noVatItemTotal(): number {
    return this.itemTotal - this.vatPrice;
  }

  get noVatTotal(): number {
    let total = this.itemTotal;

    if (this.additionalCost) {
      total += parseFloat(this.additionalCost.toString());
    }

    if (this.deliveryCost) {
      total += parseFloat(this.deliveryCost.toString());
    }

    if (this.discount) {
      total -= parseFloat(this.discount.toString());
    }

    return total - this.vatPrice;
  }

  get isInRestaurant(): boolean {
    return this.orderType === ORDER_TYPE.inRestaurant;
  }

  get additionalCost(): number {
    const orderType = this.restaurant.getOrderTypeByKey(this.orderType);
    let cost =
      orderType && orderType.additionalCost
        ? parseFloat(orderType.additionalCost)
        : 0;

    return NumberHelper.roundToTwoDecimals(cost * this.cart.length);
  }

  get deliveryCost(): number {
    const deliveryRule = this.restaurant.getDeliveryRuleForZipcode(
      this.address?.zipcode || ""
    );
    return deliveryRule && this.isDelivery
      ? parseFloat((deliveryRule.deliveryAmount * this.cart.length).toString())
      : 0;
  }

  get orderTypeObj(): OrderTypeModel {
    return this.restaurant.getOrderTypeByKey(this.orderType);
  }

  get availablePaymentMethods(): PaymentMethodModel[] {
    if (!this.coupon) {
      return this.orderTypeObj.activePaymentMethods;
    }

    return this.orderTypeObj.activePaymentMethods.filter((method) =>
      this.coupon.getPaymentTypes().includes(method.paymentMethod)
    );
  }

  get hasAvailablePaymentMethods(): boolean {
    return this.availablePaymentMethods.length > 0;
  }

  get hasCashPayment(): boolean {
    return this.availablePaymentMethods.some(
      (method) => method.paymentMethod === PAYMENT_METHOD.cash
    );
  }

  get hasOnlyCashPayment(): boolean {
    return this.hasCashPayment && this.availablePaymentMethods.length === 1;
  }

  get hasOnlinePayment(): boolean {
    return (
      this.availablePaymentMethods.filter(
        (method) => method.paymentMethod !== PAYMENT_METHOD.cash
      ).length > 0
    );
  }

  get discount(): number {
    if (this.coupon && this.hasAvailablePaymentMethods) {
      return this.coupon.getDiscountValue(this.calculateTotal());
    }

    return 0;
  }

  get itemCount() {
    return this.cart.reduce(
      (count, group) => count + group.orderItems.length,
      0
    );
  }

  get isEmpty() {
    return this.itemCount < 1;
  }

  get initialValues() {
    return {
      total: 0,
      tip: 0,
      cart: [],
    };
  }

  get childModels() {
    return {
      cart: OrdersModel,
      address: AddressModel,
      restaurant: RestaurantModel,
      coupon: CouponModel,
    };
  }

  productTotal(): number {
    return (this.total = NumberHelper.roundToTwoDecimals(this.itemTotal));
  }

  calculateTotal(): number {
    return (this.total = NumberHelper.roundToTwoDecimals(
      this.itemTotal + this.additionalCost + this.deliveryCost
    ));
  }

  calculateTotalWithTip(): number {
    return (this.total = NumberHelper.roundToTwoDecimals(
      this.calculateTotal() + this.tip
    ));
  }

  calculateTotalWithTipAndDiscount(): number {
    return (this.total = NumberHelper.roundToTwoDecimals(
      this.calculateTotalWithTip() - this.discount
    ));
  }

  getTotalQuantity() {
    return this.cart.reduce((count, group) => {
      return (
        count +
        group.orderItems.reduce((groupTotal, item) => {
          return groupTotal + item.quantity;
        }, 0)
      );
    }, 0);
  }

  getProductTotalQuantity(productId: number) {
    return this.cart.reduce((total, group) => {
      const matchingItems = group.orderItems.filter(
        (item) => item.productId === productId
      );
      return (
        total +
        matchingItems.reduce((groupTotal, item) => {
          return groupTotal + item.quantity;
        }, 0)
      );
    }, 0);
  }

  getProductItemCount(productId: number) {
    return this.cart.reduce((count, group) => {
      return (
        count +
        group.orderItems.filter((item) => item.productId === productId).length
      );
    }, 0);
  }

  addToCart(cartItem: CartItemModel, orders?: OrdersModel) {
    let existingGroup;

    if (orders) {
      existingGroup = this.cart.find(
        (group) =>
          group.deliverAt === orders?.deliverAt ||
          !group.hasOwnProperty("deliverAt")
      );
    } else {
      existingGroup = this.cart.find(
        (group) =>
          group.deliverAt === this.deliverAt ||
          !group.hasOwnProperty("deliverAt")
      );
    }

    if (existingGroup) {
      const itemToUpdate = existingGroup.orderItems.find(
        (item) => item.key === cartItem.key
      );
      if (itemToUpdate) {
        itemToUpdate.quantity++;
        itemToUpdate.calculateTotals();
      } else {
        cartItem.calculateTotals();
        existingGroup.orderItems.push(cartItem);
      }
    } else {
      cartItem.calculateTotals();
      const newGroup = new OrdersModel();
      newGroup.deliverAt = this.deliverAt;
      newGroup.orderItems = [cartItem];
      this.cart.push(newGroup);
      this.sortOrderItemsByDeliverAt();
    }

    this.calculateTotalWithTipAndDiscount();
  }

  removeFromCart(cartItem: CartItemModel, orders: OrdersModel) {
    this.removeFromCartByProperty(cartItem, orders, "key");
  }

  removeFromCartByProductId(cartItem: CartItemModel, orders: OrdersModel) {
    this.removeFromCartByProperty(cartItem, orders, "productId");
  }

  removeFromCartByProperty(
    cartItem: CartItemModel,
    orders: OrdersModel,
    property: string
  ) {
    const existingGroup = this.cart.find(
      (group) => group.deliverAt === orders?.deliverAt
    );
    if (!existingGroup) {
      return;
    }

    const existingItem = existingGroup.orderItems.find(
      (item) => item[property] === cartItem[property]
    );
    if (!existingItem) {
      return;
    }

    existingItem.quantity--;
    existingItem.calculateTotals();
    if (existingItem.quantity < 1) {
      existingGroup.orderItems = existingGroup.orderItems.filter(
        (item) => item[property] !== cartItem[property]
      );
    }

    this.calculateTotalWithTipAndDiscount();
    this.checkIsCartEmpty();
  }

  get totalBlockingTime(): number {
    if (this.orderType === ORDER_TYPE.delivery) {
      return this.restaurant.preparationTime + this.restaurant.deliveryTime;
    }
    return this.restaurant.preparationTime;
  }

  checkIsCartEmpty() {
    if (!this.hasItems) {
      this.empty();
      this.total = NumberHelper.roundToTwoDecimals(0);
    }
  }

  empty(): void {
    this.cart = [];
  }

  sortOrderItemsByDeliverAt() {
    this.cart.sort((a, b) => {
      const deliverAtA = a.deliverAt
        ? moment(a.deliverAt)
        : moment("1999-12-31");
      const deliverAtB = b.deliverAt
        ? moment(b.deliverAt)
        : moment("1999-12-31");

      if (deliverAtA.isBefore(deliverAtB)) {
        return -1;
      } else if (deliverAtA.isAfter(deliverAtB)) {
        return 1;
      }
      return 0;
    });
  }
}

export class OrdersModel extends BaseModel {
  deliverAt: string;
  orderItems: CartItemModel[];

  get initialValues() {
    return {
      orderItems: [],
    };
  }

  get childModels() {
    return {
      orderItems: CartItemModel,
    };
  }

  get deliverAtDate(): string {
    return this.deliverAt ? moment(this.deliverAt).format("DD.MM.YYYY") : "";
  }

  get deliverAtTime(): string {
    return this.deliverAt ? moment(this.deliverAt).format("HH:mm") : "";
  }
}

export class CartItemModel extends BaseModel {
  productId: number;
  name: string;
  note: string;
  unitPrice: number;
  quantity: number;
  addonPrice: number;
  totalPrice: number;
  addons: AddonModel[];
  image: ImageModel;

  defaultImageUrl = "/assets/img/placeholder-meal.jpg";

  get key() {
    return (
      `${this.productId}_` +
      this.addons.map((o) => o.id).join("_") +
      `_${this.note}`
    );
  }

  get hasAddons() {
    return this.addons.length > 0;
  }

  get initialValues() {
    return {
      quantity: 0,
      total: 0,
      addons: [],
    };
  }

  get childModels() {
    return {
      addons: AddonModel,
    };
  }

  get addonString() {
    return this.addons.map((o) => o.name).join(", ");
  }

  calculateTotals() {
    this.addonPrice = NumberHelper.roundToTwoDecimals(
      this.addons.map((o) => o.price).reduce((a, b) => a + b, 0)
    );
    this.totalPrice = NumberHelper.roundToTwoDecimals(
      this.quantity * (this.unitPrice + this.addonPrice)
    );
  }

  getImageUrl(specs = "") {
    if (this.image && this.image.url) {
      if (this.image && this.image.id) {
        return ImageHelper.createUrl(this.image.id, specs);
      }
    }
    return this.defaultImageUrl;
  }
}
