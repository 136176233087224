import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { OrdersModel, CartItemModel, CartModel } from "../../models/cart.model";
import { CartService } from "../../services/cart.service";

@Component({
  selector: "cart-items",
  templateUrl: "./cart-items.component.html",
  styleUrls: ["./cart-items.component.scss"],
})
export class CartItemsComponent implements OnInit {
  @Input() isCheckoutPreview: boolean = false;

  @Input() cart: CartModel;
  @Input() rowClass: string;
  @Output() itemAdded: EventEmitter<{
    cartItem: CartItemModel;
    orders: OrdersModel;
  }> = new EventEmitter<{
    cartItem: CartItemModel;
    orders: OrdersModel;
  }>();
  @Output() proceedToCheckoutClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() itemRemoved: EventEmitter<{
    cartItem: CartItemModel;
    orders: OrdersModel;
  }> = new EventEmitter<{
    cartItem: CartItemModel;
    orders: OrdersModel;
  }>();

  constructor(
    private cartService: CartService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  removeFromCart(cartItem: CartItemModel, orders: OrdersModel) {
    this.cartService.removeFromCart(cartItem, orders);
    this.itemRemoved.emit({ cartItem, orders });
    this.changeDetectorRef.detectChanges();
  }

  addToCart(cartItem: CartItemModel, orders: OrdersModel) {
    this.cartService.addToCart(cartItem, orders);
    this.itemAdded.emit({ cartItem, orders });
    this.changeDetectorRef.detectChanges();
  }

  emptyCart() {
    this.cartService.emptyCart();
    this.changeDetectorRef.detectChanges();
  }

  closeCartModal() {
    this.cartService.closeCartModal();
  }

  onCheckout() {
    this.cartService.closeCartModal();
    this.proceedToCheckoutClicked.emit();
  }
}
