import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TermsAndConditionsRoutingModule } from './terms-and-conditions-routing.module';



@NgModule({
    declarations: [TermsAndConditionsComponent],
    exports: [
        TermsAndConditionsComponent
    ],
    imports: [
        CommonModule,
        TermsAndConditionsRoutingModule
    ]
})
export class TermsAndConditionsModule { }
