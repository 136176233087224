import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CartItemModel } from "../../models/cart.model";
import { AddonModel, ProductModel } from "../../models/product.model";
import { CartService } from "../../services/cart.service";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "product-modal",
  templateUrl: "./product-modal.component.html",
  styleUrls: ["./product-modal.component.scss"],
})
export class ProductModalComponent implements OnInit {
  private modalRef: NgbModalRef;

  @Input() product: ProductModel;
  @Input() category: any;
  @Input() cartItem: CartItemModel;
  @Output() itemAdded: EventEmitter<CartItemModel> =
    new EventEmitter<CartItemModel>();
  @Output() itemRemoved: EventEmitter<CartItemModel> =
    new EventEmitter<CartItemModel>();
  @ViewChild("addonModal", { static: false }) private addonModal;

  addonsForm: UntypedFormGroup;
  isProductButton: boolean;
  maxAddonsReached = false;
  showAdditionalRequirements: boolean = false;
  addonMessage: string = "";
  countItems: number = 1;

  constructor(
    private modalService: NgbModal,
    private cartService: CartService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.isProductButton = this.product ? true : false;
    this.cartItem = this.cartItem || new CartItemModel();
  }

  incrementItems() {
    this.countItems++;
  }

  decrementItems() {
    if (this.countItems > 1) {
      this.countItems--;
    }
  }

  openProductModal() {
    this.initForm();
    this.modalRef = this.modalService.open(this.addonModal, {
      windowClass: "menu-modal",
    });
    this.initialCheck();
  }

  submitAddonForm() {
    if (this.addonsForm.valid) {
      for (let i = 0; i < this.countItems; i++) {
        const cartItem = this.createCartItem(this.addonsForm.value);
        this.cartService.addToCart(cartItem, undefined);
        this.itemAdded.emit(cartItem);
        this.maxAddonsReached = false;
      }
      this.modalRef.close();
      this.addonsForm.reset();
    } else {
      this.addonsForm.updateValueAndValidity();
      this.addonsForm.markAllAsTouched();
    }
    this.cartService.checkSwitchDateTimeAvailability()
  }

  getTotalPrice(): number {
    let totalPrice = this.countItems * this.product.price;

    if (this.addonsForm.valid) {
      for (const addonId in this.addonsForm.value.addons) {
        if (this.addonsForm.value.addons[addonId]) {
          const addon = this.product.addons.find(
            (o) => o.id.toString() === addonId.toString()
          );
          totalPrice += this.countItems * addon.price;
        }
      }
    }

    return totalPrice;
  }

  get quantity() {
    return this.isProductButton
      ? this.cartService.getProductTotalQuantity(this.product.id)
      : this.cartItem.quantity;
  }

  private initForm() {
    const addonGroup = new UntypedFormGroup({});
    this.showAdditionalRequirements = false;

    this.product.addons.forEach((addon: AddonModel) => {
      addonGroup.addControl(addon.id.toString(), new UntypedFormControl(null));
    });

    this.addonsForm = new UntypedFormGroup({
      note: new UntypedFormControl(null),
      addons: addonGroup,
    });
  }

  private createCartItem(form: any) {
    const addons: AddonModel[] = [];

    for (const addonId in form.addons) {
      if (form.addons[addonId] === true) {
        const addon = this.product.addons.find(
          (o) => o.id.toString() === addonId.toString()
        );
        addons.push(addon);
      }
    }

    return new CartItemModel({
      name: this.product.name,
      productId: this.product.id,
      unitPrice: this.product.price,
      note: form.note,
      image: this.product.image,
      addons,
      quantity: 1,
    });
  }

  checkAddonCount(addonId, e): void {
    let count = 0;
    const checked = e.target.checked;
    for (const control of Object.keys(
      (this.addonsForm.controls.addons as UntypedFormGroup).controls
    )) {
      if (
        (this.addonsForm.controls.addons as UntypedFormGroup).controls[control]
          .value === true
      ) {
        count++;
      }
    }
    this.maxAddonsReached =
      this.product.maxAddon || this.product.maxAddon === 0
        ? count >= this.product.maxAddon
        : false;

    const remainingAddons = this.product.maxAddon - count;

    const remainingSentence = _(this.translate.instant('You have {{remainingAddons}} addon left to add.', {remainingAddons: remainingAddons}));

    const maxReachedSentence = _(this.translate.instant('You have Reached the maximum number of addons.'));

    if (remainingAddons > 0) {
      this.addonMessage = remainingSentence;
    } else {
      this.addonMessage = maxReachedSentence;
    }

    if (this.product.maxAddon === 1) {
      for (const control in (this.addonsForm.controls.addons as UntypedFormGroup)
        .controls) {
        (this.addonsForm.controls.addons as UntypedFormGroup).controls[
          control
        ].setValue(false);
      }
      (this.addonsForm.controls.addons as UntypedFormGroup).controls[addonId].setValue(
        checked
      );
    } else {
      for (const control of Object.keys(
        (this.addonsForm.controls.addons as UntypedFormGroup).controls
      )) {
        if (
          (this.addonsForm.controls.addons as UntypedFormGroup).controls[control]
            .value !== true
        ) {
          if (this.maxAddonsReached) {
            (this.addonsForm.controls.addons as UntypedFormGroup).controls[
              control
            ].disable();
          } else {
            (this.addonsForm.controls.addons as UntypedFormGroup).controls[
              control
            ].enable();
          }
        }
      }
    }
  }

  private initialCheck(): void {
    let count = 0;
    this.countItems = 1;

    for (const control of Object.keys(
      (this.addonsForm.controls.addons as UntypedFormGroup).controls
    )) {
      if (
        (this.addonsForm.controls.addons as UntypedFormGroup).controls[control]
          .value === true
      ) {
        count++;
      }
    }
    this.maxAddonsReached =
      this.product.maxAddon || this.product.maxAddon === 0
        ? count >= this.product.maxAddon
        : false;
    this.addonMessage = _(this.translate.instant('Maximum {{number}} addons allowed.', {number: this.product.maxAddon}));

    for (const control of Object.keys(
      (this.addonsForm.controls.addons as UntypedFormGroup).controls
    )) {
      if (
        (this.addonsForm.controls.addons as UntypedFormGroup).controls[control]
          .value !== true
      ) {
        if (this.maxAddonsReached) {
          (this.addonsForm.controls.addons as UntypedFormGroup).controls[
            control
          ].disable();
        } else {
          (this.addonsForm.controls.addons as UntypedFormGroup).controls[
            control
          ].enable();
        }
      }
    }
  }
}
