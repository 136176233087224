import { BaseModel } from './base.model';

export class AvailabilityHoursModel extends BaseModel {

  monday: AvailabilityHourModel[];
  tuesday: AvailabilityHourModel[];
  wednesday: AvailabilityHourModel[];
  thursday: AvailabilityHourModel[];
  friday: AvailabilityHourModel[];
  saturday: AvailabilityHourModel[];
  sunday: AvailabilityHourModel[];

  get childModels() {
    return {
      monday: AvailabilityHourModel,
      tuesday: AvailabilityHourModel,
      wednesday: AvailabilityHourModel,
      thursday: AvailabilityHourModel,
      friday: AvailabilityHourModel,
      saturday: AvailabilityHourModel,
      sunday: AvailabilityHourModel
    };
  }

  get initialValues() {
    return {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    };
  }

  public get hasAvailabilityHours(): boolean {
    return Object.keys(this).some(key => this[key]?.length != 0);
  }

  public hasAvailabilityHoursForDay(dayOfWeek: string): boolean {
    return this[dayOfWeek]?.length > 0;
  }

}

export class AvailabilityHourModel extends BaseModel {

  startTime: string;
  endTime: string;

}

