import { Component, Inject, Input, OnInit, PLATFORM_ID } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { TranslationService } from "../../../modules/i18n/translation.service";
import { LANGUAGES } from "../../globals";
import { getWindow } from "ssr-window";
import { isPlatformBrowser } from '@angular/common';

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() userMenu: boolean;
  @Input() dropdownItemsPadding = false;
  isDropdownOpen: boolean = false;

  isCollapsed = true;
  language: LanguageFlag;
  languages: LanguageFlag[] = LANGUAGES;
  dropdownHidden = false;

  constructor(
    private translationService: TranslationService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.initializeDropdownSelection();
        this.setLanguage(this.translationService.getLanguageFromStorage());
      }); // Change detector not working for some reason so we are forcing change detection with a timeout
    } else {
      this.initializeDropdownSelection();
      this.setLanguage(this.translationService.getLanguageFromStorage());
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => this.initializeDropdownSelection());

    TranslationService.TRANSLATED.subscribe((lang) => this.setDropdownDropdownSelection(lang));
  }

  setLanguageWithRefresh(lang) {
    this.setLanguage(lang);
    getWindow().location?.reload();
  }

  setLanguage(lang) {
    this.dropdownHidden = true;
    this.translationService.setLanguage(lang);
  }

  initializeDropdownSelection(): any {
    this.setDropdownDropdownSelection(
      this.translationService.getLanguageFromStorage()
    );
  }

  protected setDropdownDropdownSelection(lang) {
    this.languages.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
