import { Injectable } from "@angular/core";
import { OrdersModel, CartItemModel, CartModel } from "../models/cart.model";
import { MenuModel, TableModel } from "../models/menu.model";
import { RestaurantModel } from "../models/restaurant.model";
import { BehaviorSubject, Subject } from "rxjs";
import { CouponModel } from "../models/coupon.model";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ADDRESS_CACHE_KEY, ORDER_CACHE_KEY, TIP_VALUE } from "../globals";
import * as moment from "moment";
import { LocalStorage } from "./storage.service";

const LAST_USED_CART_KEY = "cart-key";

@Injectable({
  providedIn: "root",
})
export class CartService {
  private CART$ = new BehaviorSubject<CartModel>(new CartModel({}));
  private cartDateModalDismissed = new Subject<void>();
  private proceedToCheckout = new Subject<void>();
  private openCartModalSource = new Subject<void>();
  cart$ = this.CART$.asObservable();
  private restaurantSlug = "";
  private tableCode = "";
  private cartModalRef: NgbModalRef;
  private orderAmountSubject = new BehaviorSubject<number>(0);
  orderAmount$ = this.orderAmountSubject.asObservable();

  openCartModal$ = this.openCartModalSource.asObservable();
  cartDateModalDismissed$ = this.cartDateModalDismissed.asObservable();
  proceedToCheckout$ = this.proceedToCheckout.asObservable();
  isSwitchDateTimeEnabled: boolean = false;
  private set _cart(value: CartModel) {
    this.CART$.next(value);
  }

  private get _cart(): CartModel {
    return this.CART$.value;
  }

  constructor(
    private modalService: NgbModal,
    private storage: LocalStorage
  ) {
    this._cart = this.getFromLocalStorage();
  }

  initCart(menu: MenuModel): void {
    this.cleanUpLocalStorage();

    if (
      this._cart.orderType != menu.orderType ||
      this._cart.restaurantSlug != menu.restaurant.slug ||
      this._cart.tableCode != menu.table?.code
    ) {
      this._cart = new CartModel({
        restaurant: menu.restaurant,
        restaurantSlug: menu.restaurant.slug,
        tableCode: menu.table?.code,
        orderType: menu.orderType,
      });

      this.saveToLocalStorage();
    }
  }

  getCart(): CartModel {
    return this._cart;
  }

  emptyCart(): void {
    this._cart.empty();
    this.storage.removeItem(this.cacheKey);
    this.checkSwitchDateTimeAvailability();
  }

  addToCart(cartItem: CartItemModel, orders: OrdersModel): void {
    this._cart.addToCart(cartItem, orders);
    this.saveToLocalStorage();
    this.checkSwitchDateTimeAvailability();
    this.orderAmountSubject.next(this._cart.itemTotal);
  }

  removeFromCart(cartItem: CartItemModel, orders: OrdersModel): void {
    this._cart.removeFromCart(cartItem, orders);
    this.saveToLocalStorage();
    this.checkSwitchDateTimeAvailability();
    this.orderAmountSubject.next(this._cart.itemTotal);
  }

  removeFromCartByProductId(
    cartItem: CartItemModel,
    orders: OrdersModel
  ): void {
    this._cart.removeFromCartByProductId(cartItem, orders);
    this.saveToLocalStorage();
  }

  getTotalQuantity(): number {
    return this._cart.getTotalQuantity();
  }

  getProductTotalQuantity(productId: number): number {
    return this._cart.getProductTotalQuantity(productId);
  }

  getProductItemCount(productId: number): number {
    return this._cart.getProductItemCount(productId);
  }

  setRestaurant(restaurant: RestaurantModel): void {
    this._cart.restaurant = restaurant;
    this.saveToLocalStorage();
  }

  setDeliveryDate(date: string): void {
    this._cart.deliverAt = date;
    this.saveToLocalStorage();
  }

  setTable(table: TableModel): void {
    this._cart.tableCode = table?.code;
    this._cart.tableName = table?.name;
    this.saveToLocalStorage();
  }

  setCoupon(coupon: CouponModel): void {
    this._cart.couponCode = coupon.code;
    this._cart.coupon = coupon;
    this.saveToLocalStorage();
  }

  removeCoupon(): void {
    this._cart.couponCode = null;
    this._cart.coupon = null;
    this.saveToLocalStorage();
  }

  setOrderType(type: string): void {
    this._cart.orderType = type;
    this.saveToLocalStorage();
  }

  allItemsAvailable(menu: MenuModel, date: string, blockingTime: number) {
    let allAvailable = true;

    this._cart.cart.forEach((cartItem) => {
      cartItem.orderItems.forEach((orderItem) => {
        if (!this.isItemAvailable(menu, orderItem, date, blockingTime)) {
          allAvailable = false;
        }
      });
    });

    return allAvailable;
  }

  checkSwitchDateTimeAvailability() {
    if (this._cart.hasItems) {
      this.isSwitchDateTimeEnabled = true;
    } else {
      this.isSwitchDateTimeEnabled = false;
    }
  }

  dismissCartDateModal() {
    this.cartDateModalDismissed.next();
  }

  proceeedToCheckout() {
    this.proceedToCheckout.next();
  }

  openCartModal(content: any) {
    if (!this.cartModalRef) {
      this.cartModalRef = this.modalService.open(content, {
        windowClass: "cart-modal cart-modal-bottom-to-top",
      });

      this.cartModalRef.result.finally(() => {
        this.cartModalRef = null;
      });
    }
  }

  openCartModalFromMenu() {
    if (this.cartModalRef) {
      this.cartModalRef.dismiss();
      this.cartModalRef = null;
    }
    this.openCartModalSource.next();
  }

  closeCartModal() {
    if (this.cartModalRef) {
      this.cartModalRef.close();
    }
  }

  removeUnavailableItems(
    menu: MenuModel,
    date: string,
    blockingTime: number
  ): void {
    this._cart.cart.forEach((cartItem) => {
      if (!moment(cartItem.deliverAt).isSame(moment(menu.date), 'day')) {
        return;
      }

      cartItem.orderItems.forEach((orderItem) => {
        if (!this.isItemAvailable(menu, orderItem, date, blockingTime)) {
          this.removeFromCart(orderItem, cartItem);
        }
      });
    });
  }

  isCartEmpty() {
    return !this._cart.hasItems;
  }

  setSlugAndTableAndGetCartFromCache(restaurantSlug: string, tableCode): void {
    this.restaurantSlug = restaurantSlug;
    this.tableCode = tableCode;
    this._cart = this.getFromLocalStorage();
  }

  private isItemAvailable(
    menu: MenuModel,
    item: CartItemModel,
    date: string,
    blockingTime: number
  ): boolean {
    for (const category of menu.menu) {
      const product = category.products.find(
        (product) => product.id === item.productId
      );
      if (product) {
        return category.category.isAvailableForDayAndHour(date, blockingTime);
      }
    }
    return false;
  }

  private saveToLocalStorage(): void {
    this.storage.setItem(this.cacheKey, JSON.stringify(this._cart));
    this.storage.setItem(LAST_USED_CART_KEY, this.cacheKey);
  }

  private getFromLocalStorage(): CartModel {
    const cartString = this.storage.getItem(this.cacheKey);

    if (cartString) {
      return new CartModel(JSON.parse(cartString));
    }

    return new CartModel();
  }

  private get cacheKey(): string {
    if (!this.restaurantSlug) {
      return this.storage.getItem(LAST_USED_CART_KEY);
    }

    const now = moment();
    const dayMonthYear = now.format("DD-MM-yyyy");

    return `${dayMonthYear}-dinego-cart-${this.restaurantSlug}${this.tableCode ? "-" + this.tableCode : ""
      }`;
  }

  private cleanUpLocalStorage(): void {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    Object.keys(this.storage)
      .filter((key) => key.includes("-dinego-cart-"))
      .forEach((key) => {
        const parts = key.split("-");

        if (parts.length > 1) {
          const storedDate = new Date(
            parseInt(parts[2]),
            parseInt(parts[1]) - 1,
            parseInt(parts[0])
          );

          if (storedDate < today) {
            this.storage.removeItem(key);
          }
        }
      });
  }

  setOrderAddress(data) {
    this.storage.setItem(ADDRESS_CACHE_KEY, JSON.stringify(data));
  }

  loadOrderAddress() {
    return JSON.parse(this.storage.getItem(ADDRESS_CACHE_KEY));
  }

  removeOrderAddress() {
    this.storage.removeItem(ADDRESS_CACHE_KEY);
  }

  getTipValue() {
    return JSON.parse(this.storage.getItem(TIP_VALUE));
  }

  setTipValue(value) {
    this.storage.setItem(TIP_VALUE, JSON.stringify(value));
  }

  removeTipValue() {
    this.storage.removeItem(TIP_VALUE);
  }

  removeLastOrder() {
    this.storage.removeItem(ORDER_CACHE_KEY);
  }
}
