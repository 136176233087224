import { Component, Input, OnInit } from '@angular/core';
import { ProductModel } from '../../models/product.model';


@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss'],
})
export class ProductImageComponent implements OnInit {

  @Input() product: ProductModel;

  constructor() {

  }

  ngOnInit(): void {
  }

  get isImagePortrait(): boolean {
    if (!this.product.image) {
      return false;
    }

    return this.product.image.width ? this.product.image.height >= this.product.image.width : false;
  }


}
