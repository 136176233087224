import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslationModule } from "../modules/i18n/translation.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbCollapseModule, NgbDropdownModule, NgbToastModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { LoaderComponent } from "./components/loader/loader.component";
import { CartItemsComponent } from "./components/cart-items/cart-items.component";
import { OrderOptionsComponent } from "./components/order-options/order-options.component";
import { AddressFormComponent } from "./components/address-form/address-form.component";
import { ImageUploadComponent } from "./components/image-upload/image-upload.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { LanguageSelectorComponent } from "./components/language-selector/language-selector.component";
import { StripSpecialCharsPipe } from "./pipes/strip-special-chars.pipe";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { DialogComponent } from "./components/dialog/dialog.component";
import { ProductImageComponent } from "./components/product-image/product-image.component";
import { TagBadgeComponent } from "./components/badge/tag-badge.component";
import { ProductModalComponent } from "./components/product-modal/product-modal.component";
import { UserMenuComponent } from "./components/user-nav/user-menu.component";
import { AuthModule } from "../modules/auth/auth.module";
import { CustomCurrencyPipe } from "./pipes/custom-currency.pipe";
import { CustomHtmlCurrencyPipe } from "./pipes/custom-html-currency.pipe";
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";

@NgModule({
  declarations: [
    LoaderComponent,
    CartItemsComponent,
    OrderOptionsComponent,
    AddressFormComponent,
    ImageUploadComponent,
    NotificationComponent,
    LanguageSelectorComponent,
    StripSpecialCharsPipe,
    CustomCurrencyPipe,
    CustomHtmlCurrencyPipe,
    ConfirmationModalComponent,
    DialogComponent,
    ProductImageComponent,
    TagBadgeComponent,
    ProductModalComponent,
    UserMenuComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    FormsModule,
    RouterModule,
    NgbTooltipModule,
    NgbCollapseModule,
    GoogleMapsModule,
    NgxGpAutocompleteModule,
    ReactiveFormsModule,
    NgbToastModule,
    NgbDropdownModule,
    AuthModule,
  ],
  exports: [
    ReactiveFormsModule,
    TranslationModule,
    LoaderComponent,
    CartItemsComponent,
    OrderOptionsComponent,
    AddressFormComponent,
    ImageUploadComponent,
    NotificationComponent,
    LanguageSelectorComponent,
    ConfirmationModalComponent,
    StripSpecialCharsPipe,
    CustomCurrencyPipe,
    CustomHtmlCurrencyPipe,
    DialogComponent,
    ProductImageComponent,
    TagBadgeComponent,
    ProductModalComponent,
    UserMenuComponent,
  ],
})
export class SharedModule { }
