import { Injectable } from "@angular/core";
import { extend, getDocument, ssrDocument } from "ssr-window";

extend(ssrDocument, {
    getElementsById: (id) => { },
    getElementsByTagName: (name) => { },
    createElement: (tagName) => { },
});

@Injectable({
    providedIn: "root",
})
export class ScriptService {

    loadCssScript(url: string, scriptId: string = null) {
        if (!url) {
            return;
        }

        let script = scriptId ? getDocument().getElementById(scriptId) as HTMLLinkElement : null;
        if (script) {
            script.href = url;
        } else {
            const style = getDocument().createElement("link");
            const head = getDocument().getElementsByTagName("head");
            if (style && head) {
                style.id = scriptId;
                style.rel = "stylesheet";
                style.href = url;
                head[0].appendChild(style);
            }
        }
    }

    loadJsScriptDefered(url, scriptId: string = null) {
        return new Promise((resolve, reject) => {
            let script = scriptId ? getDocument().getElementById(scriptId) as HTMLScriptElement : null;
            if (script) {
                script.src = url;
                resolve(true);
            } else {
                const script = getDocument().createElement('script');
                const body = getDocument().getElementsByTagName("body");
                if (script && body) {
                    script.id = scriptId;
                    script.type = 'text/javascript';
                    script.src = url;
                    script.text = ``;
                    script.async = true;
                    script.defer = true;
                    script.onload = resolve;
                    script.onerror = reject;
                    body[0].appendChild(script);
                } else {
                    resolve(false);
                }
            }
        })
    }
}