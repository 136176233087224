import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { RestaurantService } from '../services/restaurant.service';

@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe extends CurrencyPipe implements PipeTransform {

  transform(value: number | string | null | undefined): null;
  transform(value: number | string | null | undefined): string | null {
    if (!isValue(value)) return null;

    const currency = RestaurantService.getCurrencyConfig();
    return currency.isPrefix ? `${currency.value}${super.transform(value, ` `)}` : `${super.transform(value, ` `)}${currency.value}`;
  }
}

function isValue(
  value: number | string | null | undefined
): value is number | string {
  return !(value == null || value === '' || value !== value);
}
