import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "tag-badge",
  templateUrl: "./tag-badge.component.html",
  styleUrls: ["./tag-badge.component.scss"],
})
export class TagBadgeComponent {
  @Input() iconClass: string;
  @Input() imageSrc: string;
  @Input() altText: string;
  @Input() name: string;
  @Output() badgeClick: EventEmitter<string> = new EventEmitter<string>();

  onBadgeClick(): void {
    this.badgeClick.emit(this.name);
  }
}
