import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoaderService } from './loader.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @ViewChild('loader', { static: true }) loader: ElementRef;

  constructor(
    private el: ElementRef,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.loaderService.init(this.loader);
  }

  get logoUrl() {
    return environment.appLogo;
  }
}
