import { LAST_VISITED_RESTAURANT_SLUG, LAST_VISITED_RESTAURANT_TABLE_CODE, RESTAURANTS_URL, USER_PROFILE_URL } from "../globals";

export class RedirectHelper {
  public static get loginRedirectUrl(): string {
    return RedirectHelper.lastVisitedRestaurantUrl || USER_PROFILE_URL;
  }

  public static get logoutRedirectUrl(): string {
    return RedirectHelper.lastVisitedRestaurantUrl || "/auth/login";
  }

  public static get lastVisitedRestaurantUrl(): string {
    let restaurantSlug = localStorage.getItem(LAST_VISITED_RESTAURANT_SLUG); //FIXME use LocalSTorage service, not local storage directly, due to SSR
    let tableCode = localStorage.getItem(LAST_VISITED_RESTAURANT_TABLE_CODE); //FIXME use LocalSTorage service, not local storage directly, due to SSR

    restaurantSlug = restaurantSlug != "undefined" ? restaurantSlug : null;
    tableCode = tableCode != "undefined" ? tableCode : null;

    return restaurantSlug ? `/${RESTAURANTS_URL}/${restaurantSlug}/welcome` + (tableCode ? "/" + tableCode : "") : null;
  }
}
