import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthComponent } from './auth.component';
import { TranslationModule } from '../i18n/translation.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {PayyoTermsAndConditionsModule} from "../../pages/payyo-terms-and-conditions/payyo-terms-and-conditions.module";
import {PrivacyPolicyModule} from "../../pages/privacy-policy/privacy-policy.module";
import {TermsAndConditionsModule} from "../../pages/terms-and-conditions/terms-and-conditions.module";

@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    AuthComponent,
    ResetPasswordComponent,
  ],
    imports: [
        CommonModule,
        TranslationModule,
        AuthRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        PayyoTermsAndConditionsModule,
        PrivacyPolicyModule,
        TermsAndConditionsModule,
    ],
  exports: [
    LoginComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    LogoutComponent,
  ]
})
export class AuthModule { }
