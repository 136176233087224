export class AuthModel {
  authToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  scope: string;

  setAuth(response: any) {
    this.authToken = response.access_token;
    this.refreshToken = response.refresh_token;
    this.expiresIn = response.expires_in;
    this.tokenType = response.token_type;
    this.scope = response.scope;

    return this;
  }
}
