import { ORDER_TYPES, PAYMENT_METHOD, PAYMENT_METHODS } from '../globals';
import { BaseModel } from './base.model';

export class OrderTypeModel extends BaseModel {
  static DEFAULT_TYPE = 'delivery';
  static TYPE_DELIVERY = 'delivery';
  static TYPE_TAKEAWAY = 'takeaway';
  static TYPE_IN_RESTAURANT = 'in-restaurant';

  id: number;
  orderType: string;
  additionalCost: string;
  vatPercent: string;
  isActive?: boolean;
  paymentMethods: PaymentMethodModel[];

  get initialValues() {
    return {
      isActive: false,
      paymentMethods: [],
    };
  }

  get childModels() {
    return {
      paymentMethods: PaymentMethodModel,
    }
  }

  get activePaymentMethods() {
    return this.paymentMethods.filter((o: PaymentMethodModel) => o.isActive);
  }

  get hasCashPayment(): boolean {
    return !!this.activePaymentMethods.find(method => method.paymentMethod === PAYMENT_METHOD.cash);
  }

  get hasOnlyCashPayment(): boolean {
    return this.hasCashPayment && this.activePaymentMethods.length === 1;
  }

  get hasTwintPayment(): boolean {
    return !!this.activePaymentMethods.find(method => method.paymentMethod === PAYMENT_METHOD.twint);
  }

  get hasCreditCardPayment(): boolean {
    return !!this.activePaymentMethods.find(method => method.paymentMethod === PAYMENT_METHOD.creditCard);
  }

  get hasApplePayPayment(): boolean {
    return !!this.activePaymentMethods.find(method => method.paymentMethod === PAYMENT_METHOD.applePay);
  }

  get hasGooglePayPayment(): boolean {
    return !!this.activePaymentMethods.find(method => method.paymentMethod === PAYMENT_METHOD.googlePay);
  }

  get name(): string {
    const type = ORDER_TYPES.find(item => item.value == this.orderType);
    return type.key;
  }

  getPaymentMethodByKey(key: string) {
    return this.paymentMethods.find(o => o.paymentMethod === key);
  }

  getPaymentMethodLabelByKey(key: string) {
    const method = PAYMENT_METHODS.find(item => item.value == key);
    return method.key;
  }
}

export class PaymentMethodModel extends BaseModel {
  id: number;
  paymentMethod: string;
  isActive?: boolean;

  get initialValues() {
    return {
      isActive: false,
    };
  }

  get methodLabel() {
    const method = PAYMENT_METHODS.find(item => item.value == this.paymentMethod);
    return method.key;
  }
}
