import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateDefaultParser } from '@ngx-translate/core';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}

@Injectable()
export class LpTranslateParser extends TranslateDefaultParser {
  constructor() {
    super();
  }
  public getValue(target: any, key: string): any {
    if (target[key] == '') {
      target[key] = key;
    }
    return super.getValue.apply(this, [target, key]);
  }
}
